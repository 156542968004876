import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const StepButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginRight: theme.spacing(1),
}));

export default function BookSummary({book, handleBack}) {

  return (
    <>
      <Typography component="span" variant="body2" sx={{ display: 'block' }}>
        Titel: {book?.title}
      </Typography>
      <Typography component="span" variant="body2" sx={{ display: 'block' }}>
        {book?.authors?.size < 2 ? 'Autor: ' : 'Autoren: ' + book?.authors?.map((author) => author.firstName + ' ' + author.lastName).join(", ")}
      </Typography>
      <Typography component="span" variant="body2" sx={{ display: 'block' }}>
        ISBN-13: {book?.isbn13}
      </Typography>
      <Typography component="span" variant="body2" sx={{ display: 'block' }}>
        ISBN-10: {book?.isbn10}
      </Typography>

      <StepButton sx={{ color: 'primary.dark' }} onClick={handleBack}>Zurück</StepButton>
    </>
  )
}
