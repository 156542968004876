import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Grid from '@mui/material/Grid';
import { useParams, useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';

import { DetailNavigation } from '../Navigation';
import BookList from '../BookList';
import { getAuthor, getAuthorBooks } from '../../api/endpoints';
import { useApi, useDeviceParameter } from '../../hooks';
import { usePermissions } from '../../utils/PermissionsProvider';
import FormDrawer from '../FormDrawer/FormDrawer';
import { EditAuthorNameForm } from './EditFormComponents';

export function Author() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [author, setAuthor] = useState({});
  const [books, setBooks] = useState([]);
  const { data, executeRequest, cancelRequest: cancelAuthor } = useApi(getAuthor(id));
  const { data: booksData, executeRequest: bookRequest, cancelRequest: cancelBooks } = useApi(getAuthorBooks(id));
  const { hasPermission } = usePermissions();
  const { isMobile } = useDeviceParameter();
  const { enqueueSnackbar } = useSnackbar();

  const showMessage = () => {
    enqueueSnackbar('Noch nicht implementiert.', {
      variant: 'warning',
    });
  };

  const refreshAuthor = () => {
    executeRequest();
  }

  useEffect(() => {
    return () => {
      cancelAuthor();
      cancelBooks();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (booksData) {
      booksData?.forEach((book, i) => {
        if (typeof book.authors !== 'string') {
          book.authors = book?.authors?.map((author) => author.firstName + ' ' + author.lastName).join(", ");
        }
      });
      setBooks(booksData);
    }
  }, [booksData]); // eslint-disable-line

  useEffect(() => {
    if (data) {
      setAuthor(data);
    }
  }, [data]); // eslint-disable-line

  useEffect(() => {
    executeRequest();
    bookRequest({
      size: 100,
      page: 0,
      sort: 'title,asc'
    });
  }, []); // eslint-disable-line

  return (
    <div>
      {isMobile && (
        <DetailNavigation>
          <IconButton aria-label="back" onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Box sx={{ margin: 'auto' }} />
          {(hasPermission('delete:authors') || hasPermission('manage:authors')) &&
            <IconButton aria-label="delete" onClick={showMessage} sx={{ paddingLeft: 1.5, paddingRight: 1.5 }}>
              <DeleteOutlinedIcon />
            </IconButton>
          }
          {(hasPermission('update:authors') || hasPermission('manage:authors')) &&
            <FormDrawer form={EditAuthorNameForm} object={author} onEvent={refreshAuthor} />
          }
        </DetailNavigation>
      )}


      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        spacing={0}
        sx={{ ...(isMobile && {pt: 2, pl: 2}) }}
        >

        <Grid item>
          <Typography variant="h5" component="h1" sx={{pl:1}}>
            {author?.lastName ? author.firstName + ' ' + author.lastName : 'Autor'}
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="overline" component="h2" sx={{ pt: 1, pl: 1 }}>
            {(books?.length > 1 || books?.length === 0) ? `${books.length} Bücher` : `${books.length} Buch`}
          </Typography>
        </Grid>

        <Grid item >
          <BookList
            books={books}
            hasMore={false}
            pageSize={books?.length}
          />
        </Grid>

      </Grid>
    </div>
  );
}
