import React, { useState, useContext, createContext, useEffect } from 'react';

import { getPermissions } from '../api/endpoints';
import { useApi } from '../hooks';

const PermissionsContext = createContext(null);

export const usePermissions = () => {
  const [ permissions ] = useContext(PermissionsContext);

  const hasPermission = (permission) => {
    return permissions.includes(permission);
  }

  return {
    permissions,
    hasPermission,
  };
}

export const PermissionsProvider = ({ children }) => {
  const [ permissions, setPermissions ] = useState('');
  const { data, executeRequest, cancelRequest } = useApi(getPermissions);

  useEffect(() => {
    if (data) {
      setPermissions(data.permissions);
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    executeRequest();
    return () => {
      cancelRequest();
    }

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PermissionsContext.Provider value={[permissions, setPermissions]}>
      {children}
    </PermissionsContext.Provider>
  )
}
