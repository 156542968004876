import React from 'react';

import { InfiniteBookList, BookListItem } from './BookList.parts'

export default function BookList(props) {
  const {books, next, hasMore } = props; //pagesize would be available here

  return(
    <InfiniteBookList next={next} hasMore={hasMore} loader={<></>}>
      {books.map((book, index) => (
        <BookListItem key={index} book={book} />
      ))}
    </InfiniteBookList>
  );
};
