import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { useTheme } from '@mui/material/styles';

function ElevationScroll(props) {
  const { children } = props;
  const theme = useTheme();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
    sx: trigger
      ? { 
        backgroundColor: theme.palette.secondary.main, 
        '& svg.MuiSvgIcon-root': {color: theme.palette.secondary.textColor}
      } 
      : { 
        backgroundColor: 'transparent' 
      },
  });
}

export function DetailNavigation(props) {
  const { children, disableGutters = true } = props;

  return (
    <>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar>
          <Toolbar disableGutters={disableGutters}>
            {children}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
    </>
  );
}
