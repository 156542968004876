import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { useSnackbar } from 'notistack';

import { createLibrary, patchLibrary } from '../../api/endpoints';
import { useApi } from '../../hooks';

export function LibraryForm(props) {
  const { title, text, mode  = 'add', icon, executeFunction, library: origLibrary} = props;
  const [open, setOpen] = useState(false);
  const [library, setLibrary] = useState({
    id: origLibrary?.id ? origLibrary.id : null,
    name: origLibrary?.name ? origLibrary.name : ''
  });
  const theme = useTheme();
  const { loading: loadingCreate, error: createError, metadata: createMetadata, executeRequest: excecuteCreate, cancelRequest: cancelCreate } = useApi(createLibrary);
  const { loading: loadingPatch, error: patchError, metadata: patchMetadata, executeRequest: excecutePatch, cancelRequest: cancelPatch } = useApi(patchLibrary(library.id));
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const create = () => {
    excecuteCreate(
      {},
      {...library}
    );
  }

  const patch = () => {
    excecutePatch(
      {},
      {...library}
    );
  }

  const handleClose = () => {
    library.id ? patch() : create();
  };

  const handleCancel = () => {
    setLibrary({
      id: origLibrary?.id ? origLibrary.id : null,
      name: origLibrary?.name ? origLibrary.name : ''
    });
    setOpen(false);
  };

  const handleChange = (event) => {
    setLibrary({
      ...library,
      name: event.target.value,
    });
  }

  useEffect(() => {
    executeFunction();
    setOpen(false);
  }, [createMetadata, patchMetadata]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect (() => {
    if (createError || patchError) {
      enqueueSnackbar('Fehler beim Speichern.', {
        variant: 'error',
      });
    }
  }, [createError, patchError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      cancelPatch();
      cancelCreate();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      { mode === 'add' &&
      <IconButton edge="end" aria-label="add" onClick={handleClickOpen} color="secondary">
        {icon}
      </IconButton>
      }

      { mode === 'edit' &&
      <IconButton edge="end" aria-label="edit" onClick={handleClickOpen} color="secondary">
        {icon}
      </IconButton>
      }

      <Dialog
        open={open}
        onClose={handleCancel}
        fullScreen={useMediaQuery(theme.breakpoints.down('md'))}
        aria-labelledby="library-form-dialog-title"
        aria-describedby="library-form-dialog-description"
      >
        <DialogTitle id="library-form-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="library-form-dialog-description">
            {text}
          </DialogContentText>
          <TextField
            required id="library-name"
            label="Name"
            value={library.name}
            onChange={handleChange}
            fullWidth
            size="small"
            variant="outlined"
            margin="dense"
            color="secondary"
            error={library.name.length === 0}
            helperText={library.name.length === 0 ? 'Gib einen Namen ein.' : ''}
          />
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: 'primary.dark' }} onClick={handleCancel}>Abbrechen</Button>
          <Button variant='outlined' color="secondary" onClick={handleClose} autoFocus disabled={loadingCreate || loadingPatch}>
          {(loadingCreate || loadingPatch) && (
            <CircularProgress
              size={24}
              sx={{
                color: 'secondary.dark',
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
