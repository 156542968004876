const isbnPattern = /^(?:ISBN(?:-1[03])?:? )?(?=[0-9X]{10}$|(?=(?:[0-9]+[- ]){3})[- 0-9X]{13}$|97[89][0-9]{10}$|(?=(?:[0-9]+[- ]){4})[- 0-9]{17}$)(?:97[89][- ]?)?[0-9]{1,5}[- ]?[0-9]+[- ]?[0-9]+[- ]?[0-9X]$/;

export const isValidIsbn13 = (isbn13) => {
  if (isbnPattern.test(isbn13)) {
    // Remove non ISBN digits, then split into an array
    var chars = isbn13.replace(/[- ]|^ISBN(?:-1[03])?:?/g, "").split("");

    if (chars.length !== 13) return false;

    let sum = 0;

    for (let i = 0; i < 12; i++) {
      if (i % 2 === 0) {
        sum = sum + parseInt(chars[i], 10);
      } else {
        sum = sum + 3*parseInt(chars[i], 10);
      }
    }

    let r = 10 - sum % 10;

    if (r === 10) {
        r = 0;
    }

    if (r === parseInt(chars[12], 10)){
        return true;
    }
  }

  return false;
}

export const isValidIsbn10 = (isbn10) => {
  if (isbnPattern.test(isbn10)) {
    // Remove non ISBN digits, then split into an array
    let chars = isbn10.replace(/[- ]|^ISBN(?:-1[03])?:?/g, "").split("");

    if (chars.length !== 10) return false;

    let counter = 10;
    let product = 0;

    for (let index = 0; index < chars.length; index++) {
      product += counter * chars[index];
      counter--;
    }

    return (product%11 === 0);


  }

  return false;
}

export const isValidTitle = (title) => {
  return title.length > 0;
}

export const stopWords = [
  "a", "an", "and", "are", "as", "at", "be", "but", "by",
  "for", "if", "in", "into", "is", "it",
  "no", "not", "of", "on", "or", "p", "pu", "ro", "such",
  "that", "the", "their", "then", "there", "these",
  "they", "this", "to", "was", "will", "with"
];
