import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import { getLibraries } from '../../../api/endpoints';
import { useApi } from '../../../hooks';

export function LibraryInformation({ parentLoading, children }) {
  const [libraries, setLibraries] = useState([]);
  const { loading, data: librariesData, executeRequest: exceuteGet, cancelRequest: cancelGet } = useApi(getLibraries);

  useEffect(() => {
    librariesData && setLibraries(librariesData);
  }, [librariesData]); // eslint-disable-line

  useEffect(() => {
    exceuteGet();
    return () => {
      cancelGet();
    }
  }, []); // eslint-disable-line

  if (loading || parentLoading) {
    return (
      <Typography variant="overline" component="h2" sx={{ px: 1, flex: 1 }}>
        <Skeleton />
      </Typography>
    )
  }


  return (
    <>
      <Typography variant="overline" component="h2" sx={{ pl: 1, flex: 1 }}>
        Sammlung {libraries[0]?.name}
      </Typography>
      {children}
    </>
  );
}