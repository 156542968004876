import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const StepButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginRight: theme.spacing(1),
}));

export default function BookTitle({book, handleNext, handleBack}) {
  const [title, setTitle] = useState(book?.title ? book.title : '');
  const [error, setError] = useState({});

  const submitData = () => {
    handleNext(    {
      ...book,
      title: title,
    });
  }

  const handleChange = (event) => {
    setTitle(event.target.value);
  }

  const validateTitle = () => {
    if (title.length === 0) {
      setError({
        hasError: true,
        helperText: 'Gib einen Titel ein.'
      })
      return false;
    } else {
      setError({});
      return true;
    }
  }

  const submitHandler = (event) => {
    event.preventDefault();
    if (validateTitle()) {
      submitData();
    }
  }

  return (
    <>
      <form noValidate autoComplete="off" onSubmit={submitHandler}>
        <TextField
          error={error?.hasError}
          helperText={error?.helperText}
          required id="book-title"
          label="Titel"
          fullWidth
          value={title}
          onChange={handleChange}
          onBlur={validateTitle}
          size="small"
          variant="outlined"
          margin="dense"
          color="secondary"
        />
      </form>
      <StepButton sx={{ color: 'primary.dark' }} onClick={handleBack}>Zurück</StepButton>
      <StepButton variant='outlined'  color="secondary" onClick={submitData} disabled={title.length === 0 || error?.hasError}>Weiter</StepButton>
    </>

  )
}
