import React, { useContext, createContext } from 'react';
import { useLocalStorage } from '../../../hooks/useLocalStorage';

const RecentlyViewedBooksContext = createContext(null);

const MAX_BOOK_COUNT = 4;

export const useRecentlyViewedBooks = () => {
  const [ books, setBooks ] = useContext(RecentlyViewedBooksContext);

  const replaceBooks = (books) => {
    setBooks(books)
  }

  const addBook = (newBook) => {
    let booksTmp = books.filter(book => book.id !== newBook.id);
    booksTmp.length === MAX_BOOK_COUNT && booksTmp.pop();
    setBooks([...[newBook], ...booksTmp]);
  }

  const removeBook = (rmBook) => {
    setBooks(
      books.filter(book => book.id !== rmBook.id)
    )
  }

  return {
    books,
    replaceBooks,
    addBook,
    removeBook,
  };
}

export const RecentlyViewedBooksProvider = ({ children }) => {
  const [books, setBooks] = useLocalStorage('recentlyViewedBooks',[]);
  
  return (
    <RecentlyViewedBooksContext.Provider value={[books, setBooks]}>
      {children}
    </RecentlyViewedBooksContext.Provider>
  )
}
