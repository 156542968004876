import React, { useState, forwardRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { ScanBarCodeIcon } from '../../theme/ScanBarCode';
import { DetailNavigation } from '../Navigation';
import { useLocation, useNavigate } from 'react-router-dom';
import { useScanner, Scanner } from '../BarcodeScanner';
import { isValidIsbn13, isValidIsbn10 } from '../../utils/Validation';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledFab = styled(Fab, { shouldForwardProp: (prop) => prop !== 'isMobile' })(
  ({ theme, isMobile }) => ({
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
    margin: '0 auto',
    ...(isMobile && {
      top: -15,
    }),
    ...(!isMobile && {
      bottom: 30,
    }),
  }),
);

export default function BarcodeScanner() {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  let location = useLocation();
  let navigate = useNavigate();
  const { onChange } = useScanner();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDetected = (result) => {
    if (isValidIsbn13(result.codeResult.code) || isValidIsbn10(result.codeResult.code)) {
      onChange(result.codeResult.code);
      if ('/authors' === location.pathname) {
        navigate('/');
      }
      handleClose();
    }
  }

  return (
    <div>
      <StyledFab color="secondary" aria-label="scan" onClick={handleClickOpen} isMobile={isMobile}>
        <ScanBarCodeIcon />
      </StyledFab>
      <Dialog
        fullScreen={isMobile}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        {
          isMobile && (
            <DetailNavigation disableGutters={false}>
              <IconButton
                edge="start"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">

              </Typography>
            </DetailNavigation>
          )
        }

        <Scanner onDetected={onDetected} />

      </Dialog>
    </div>
  );
}
