const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const HEADERS = {
  accept: 'application/json',
  'Content-Type': 'application/json',
}

export const createBook = {
  method: 'post',
  url: `${API_BASE_URL}/books`,
  headers: {
    ...HEADERS,
  },
}

export const getBook = id => {
  return {
    method: 'get',
    url: `${API_BASE_URL}/books/${id}`,
    headers: {
      ...HEADERS,
    },
  }
};

export const getBookByIsbn = isbn13 => {
  return {
    method: 'get',
    url: `${API_BASE_URL}/books/isbn/${isbn13}`,
    headers: {
      ...HEADERS,
    },
  }
};

export const getBooks = {
  method: 'get',
  url: `${API_BASE_URL}/books`,
  headers: {
    ...HEADERS,
  },
};

export const searchBooks = {
  method: 'get',
  url: `${API_BASE_URL}/search`,
  headers: {
    ...HEADERS,
  },
}

export const deleteBook = id => {
  return {
    method: 'delete',
    url: `${API_BASE_URL}/books/${id}`,
    headers: {
      ...HEADERS,
    },
  }
};

export const patchBook = id => {
  return {
    method: 'patch',
    url: `${API_BASE_URL}/books/${id}`,
    headers: {
      ...HEADERS,
    },
  }
};

export const createAuthor = {
  method: 'post',
  url: `${API_BASE_URL}/authors`,
  headers: {
    ...HEADERS,
  },
}

export const getAuthors = {
  method: 'get',
  url: `${API_BASE_URL}/authors`,
  headers: {
    ...HEADERS,
  },
};

export const getAuthor = id => {
  return {
    method: 'get',
    url: `${API_BASE_URL}/authors/${id}`,
    headers: {
      ...HEADERS,
    },
  }
};

export const patchAuthor = id => {
  return {
    method: 'patch',
    url: `${API_BASE_URL}/authors/${id}`,
    headers: {
      ...HEADERS,
    },
  }
};

export const patchAuthorBookRelation = authorId => { //books are added as plain text body
  return {
    method: 'patch',
    url: `${API_BASE_URL}/authors/${authorId}/books`,
    headers: {
      ...HEADERS,
    },
  }
};

export const deleteBookFromAuthor = (authorId, bookId) => {
  return {
    method: 'delete',
    url: `${API_BASE_URL}/authors/${authorId}/books/${bookId}`,
    headers: {
      ...HEADERS,
    },
  }
};

export const getAuthorBooks = id => {
  return {
    method: 'get',
    url: `${API_BASE_URL}/authors/${id}/books`,
    headers: {
      ...HEADERS,
    },
  }
};

export const createLibrary = {
  method: 'post',
  url: `${API_BASE_URL}/libraries`,
  headers: {
    ...HEADERS,
  },
}

export const getLibraries = {
  method: 'get',
  url: `${API_BASE_URL}/libraries`,
  headers: {
    ...HEADERS,
  },
};

export const patchLibrary = id => {
  return {
    method: 'patch',
    url: `${API_BASE_URL}/libraries/${id}`,
    headers: {
      ...HEADERS,
    },
  }
};

export const patchFavorites = {
    method: 'patch',
    url: `${API_BASE_URL}/favorites`,
    headers: {
      ...HEADERS,
    }
};

export const deleteBookFromFavorites = id => {
  return {
    method: 'delete',
    url: `${API_BASE_URL}/favorites/${id}`,
    headers: {
      ...HEADERS,
    },
  }
};

export const getPermissions = {
  method: 'get',
  url: `${API_BASE_URL}/users/my/permissions`,
  headers: {
    ...HEADERS,
  },
};

export const updateSearchIndex = {
  method: 'get',
  url: `${API_BASE_URL}/search/control/index`,
  headers: {
    ...HEADERS,
  },
};
