import * as React from 'react';
import { NavLink } from "react-router-dom";
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import AddIcon from '@mui/icons-material/Add';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import BarcodeScanner from '../BarcodeScanner';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
}));

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  textDecoration: 'none',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(0.5),
  margin: 'auto',
  width: '20%',
}));

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "isActive",
})(({ theme, isActive }) => ({
  color: isActive ? theme.palette.secondary.main : theme.palette.primary.dark,
  margin: 'auto',
  paddingTop: 0,
  paddingBottom: 0,
}));

const StyledNavTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isActive",
})(({ theme, isActive }) => ({
  color: isActive ? theme.palette.secondary.main : theme.palette.primary.dark,
  margin: 'auto',
}));

const NavLinkItem = (props) => {
  const {children, path, title} = props;

  return (
    <StyledNavLink
      to={path}
      children={({isActive}) => {
        return (
          <>
            <StyledIconButton isActive={isActive}>
              {children}
            </StyledIconButton>
            <StyledNavTitle isActive={isActive} variant="caption" component="div" gutterBottom>
              {title}
            </StyledNavTitle>
          </>
        )
      }}
    />
  )
}

export default function MobileNavigation(props) {
  const { children } = props;

  return (
    <React.Fragment>
      <CssBaseline />
        {children}
      <StyledAppBar position="fixed" sx={{ top: 'auto', bottom: 0 }}>
        <Toolbar>
          <NavLinkItem path="/" title="Bücher">
            <AutoStoriesOutlinedIcon />
          </NavLinkItem>

          <NavLinkItem path="/authors" title="Autoren">
            <PeopleOutlinedIcon />
          </NavLinkItem>

          <BarcodeScanner />

          <Box sx={{ width: '15%', margin: 'auto' }} />

          <NavLinkItem path="/books/add" title="Neu">
            <AddIcon />
          </NavLinkItem>

          <NavLinkItem path="/profile" title="Profil">
            <ManageAccountsOutlinedIcon />
          </NavLinkItem>

        </Toolbar>
      </StyledAppBar>
    </React.Fragment>
  );
}
