import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { patchAuthor } from '../../../api/endpoints';
import { useApi } from '../../../hooks';

const DEFAULT_ERROR = {
  firstNameHasError: false,
  lastNameHasError: false,
  firstNameHelperText: '',
  lastNameHelperText: ''
};

export function EditAuthorNameForm(props) {
  const { setFormTitle, object: author, onSuccess, onCancel, onError, children } = props;
  const [newAuthorName, setNewAuthorName] = useState(
    author
      ? { firstName: author.firstName, lastName: author.lastName }
      : { firstName: '', lastName: '' });
  const [error, setError] = useState(DEFAULT_ERROR);

  const {
    loading: patching,
    error: patchError,
    data: patchData,
    responseStatus: patchResponseStatus,
    executeRequest: executePatch,
    cancelRequest: cancelPatch
  } = useApi(patchAuthor(author?.id));

  const handleFieldChange = (key) => (event) => {
    setNewAuthorName({
      ...newAuthorName,
      [key]: event.target.value  
    });
  }

  const handleSave = (event) => {
    event.preventDefault();
    executePatch(
      {},
      {
        firstName: newAuthorName.firstName,
        lastName: newAuthorName.lastName
      }
    );
  }

  const handleCancel = () => {
    setNewAuthorName({
      firstName: author?.firstName, lastName: author?.lastName
    })
    onCancel();
  }

  useEffect(() => {
    setError({
      ...error,
      firstNameHasError: false,
      firstNameHelperText: ''
    });
    if (newAuthorName.firstName?.length === 0) {
      setError({
        ...error,
        firstNameHasError: true,
        firstNameHelperText: 'Kein Vorname - sicher?'
      });
    }
  }, [newAuthorName.firstName]); // eslint-disable-line

  useEffect(() => {
    setError({
      ...error,
      lastNameHasError: false,
      lastNameHelperText: ''
    });
    if (newAuthorName.lastName?.length === 0) {
      setError({
        ...error,
        lastNameHasError: true,
        lastNameHelperText: 'Kein Nachname - sicher?'
      });
    }
  }, [newAuthorName.lastName]); // eslint-disable-line

  useEffect(() => {
    if(patchData) {
      onSuccess();
    }
  }, [patchData]); // eslint-disable-line

  useEffect(() => {
    if(patchError) {
      onError(`Fehler beim Speichern der Änderung. ${patchResponseStatus ? '('+patchResponseStatus+')' : ''}`);
    }
  }, [patchError]); // eslint-disable-line

  useEffect(() => {
    setFormTitle("Autor bearbeiten")
    return () => {
      cancelPatch();
    }
  }, []); // eslint-disable-line

  return (
    <>
      {children}
      <form noValidate autoComplete="off" onSubmit={handleSave}>
        <TextField
          id="author-firstname"
          required
          label={`Vorname (war: ${author?.firstName})`}
          fullWidth
          value={newAuthorName.firstName}
          onChange={handleFieldChange('firstName')}
          error={error?.firstNameHasError}
          helperText={error?.firstNameHelperText}
          size="small"
          variant="outlined"
          margin="dense"
          color="secondary"
          sx={{ my: 1 }}
        />

        <TextField
          id="author-lastName"
          required
          label={`Nachname (war: ${author?.lastName})`}
          fullWidth
          value={newAuthorName.lastName}
          onChange={handleFieldChange('lastName')}
          error={error?.lastNameHasError}
          helperText={error?.lastNameHelperText}
          size="small"
          variant="outlined"
          margin="dense"
          color="secondary"
          sx={{ mb: 1 }}
        />
      </form>
      <Grid container
        direction="column"
        justifyContent="space-evenly"
        alignItems="space-evenly"
      >

        <Button
          autoFocus
          variant="contained"
          color="secondary"
          sx={{ my: 1 }}
          onClick={handleSave}
          disabled={patching || error?.lastNameHasError || error?.firstNameHasError}
        >
          {(patching) && (
            <CircularProgress
              size={24}
              sx={{
                color: 'secondary.dark',
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
          Speichern
        </Button>

        <Button
          sx={{ my: 1 }}
          color="primary"
          variant="contained"
          disableElevation
          disabled={patching}
          onClick={handleCancel}
        >
          Abbrechen
        </Button>
      </Grid>
    </>

  );
}