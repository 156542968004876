import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TuneIcon from '@mui/icons-material/Tune';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


const FilterOptions = ({ queryParameter, onUpdate }) => {

  return (
    <Box sx={{ ml: 1, my: 1 }}>
      <ToggleButton
        value="isFavorite"
        selected={queryParameter.filter.includes('isFavorite')}
        size="small"
        color="secondary"
        onChange={(event, value) => onUpdate({
          ...queryParameter,
          pageNumber: 0,
          filter: queryParameter.filter.includes('isFavorite') ? queryParameter.filter.filter(item => item !== value) : [...queryParameter.filter, value]
        })}
      >
        {
          queryParameter.filter.includes('isFavorite')
            ? <FavoriteIcon color="secondary" fontSize="small" />
            : <FavoriteBorderIcon color="secondary" fontSize="small" />
        }
      </ToggleButton>
      <Typography variant="overline" component="h6" sx={{ lineHeight: 1.7 }}>FILTER</Typography>
    </Box>
  );
};

const NumberOptions = ({ queryParameter, onUpdate }) => {

  return (
    <Box sx={{ ml: 3, my: 1 }}>
      <ToggleButtonGroup
        value={queryParameter.pageSize}
        exclusive
        onChange={(event, newValue) => onUpdate({
          ...queryParameter,
          pageNumber: 0,
          pageSize: newValue
        })}
        size="small"
        aria-label="Buecher pro Seite"
      >
        <ToggleButton value={1} aria-label="5" sx={{ lineHeight: 1.5 }} color="secondary">
          <strong> 1</strong>
        </ToggleButton>
        <ToggleButton value={10} aria-label="10" sx={{ lineHeight: 1.5 }} color="secondary">
          <strong>10</strong>
        </ToggleButton>
        <ToggleButton value={20} aria-label="20" sx={{ lineHeight: 1.5 }} color="secondary">
          <strong>20</strong>
        </ToggleButton>
        <ToggleButton value={50} aria-label="50" sx={{ lineHeight: 1.5 }} color="secondary">
          <strong>50</strong>
        </ToggleButton>
      </ToggleButtonGroup>
      <Typography variant="overline" component="h6" sx={{ lineHeight: 1.7 }}>Bücher pro Seite</Typography>
    </Box>
  );
};

const SortOptions = ({ queryParameter, onUpdate }) => {

  return (
    <>
      <Box sx={{ ml: 1, my: 1 }}>
        <ToggleButtonGroup
          value={queryParameter.sortKey}
          exclusive
          onChange={(event, newValue) => onUpdate({
            ...queryParameter,
            pageNumber: 0,
            sortKey: newValue
          })}
          size="small"
          aria-label="Sortieren nach"
        >
          <ToggleButton value="title" aria-label="title" sx={{ lineHeight: 1.5 }} color="secondary">
            <strong>Titel</strong>
          </ToggleButton>
          <ToggleButton value="isbn13" aria-label="desc" sx={{ lineHeight: 1.5 }} color="secondary">
            <strong>ISBN-13</strong>
          </ToggleButton>
          <ToggleButton value="isbn10" aria-label="desc" sx={{ lineHeight: 1.5 }} color="secondary">
            <strong>ISBN-10</strong>
          </ToggleButton>
        </ToggleButtonGroup>
        <Typography variant="overline" component="h6" sx={{ lineHeight: 1.7 }}>Sortierung</Typography>
      </Box>
      <Box sx={{ ml: 2, my: 1 }}>
        <ToggleButtonGroup
          value={queryParameter.sortDirection}
          exclusive
          onChange={(event, newValue) => onUpdate({
            ...queryParameter,
            pageNumber: 0,
            sortDirection: newValue
          })}
          size="small"
          aria-label="Sortierrichtung"
        >
          <ToggleButton value="asc" aria-label="asc" sx={{ lineHeight: 1.5 }} color="secondary">
            <ArrowUpwardIcon color="secondary" fontSize="small" />
          </ToggleButton>
          <ToggleButton value="desc" aria-label="desc" sx={{ lineHeight: 1.5 }} color="secondary">
            <ArrowDownwardIcon color="secondary" fontSize="small" />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </>
  );
};

export const OptionsButton = styled((props) => {
  const { active, ...other } = props;
  return (
    <IconButton sx={{ p: '5px' }} {...other} >
      <TuneIcon />
    </IconButton>
  );
})(({ theme, active }) => ({
  transform: !active ? 'scaleX(1)' : 'scaleX(-1)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: 600,
  }),
}));

export const OptionsButtonBar = (props) => {
  const { visible, queryParameter, updateSearchParameter } = props;

  return (
    <Collapse
      in={visible}
      timeout={600}
      sx={{ width: "100%" }}
    >
      <Divider />
      <Box sx={{ flex: 1, display: "flex", flexWrap: "wrap" }}>
        <FilterOptions queryParameter={queryParameter} onUpdate={updateSearchParameter} />
        <NumberOptions queryParameter={queryParameter} onUpdate={updateSearchParameter} />
        <Box
          id='break'
          sx={{
            flexBasis: '100%',
            height: 0
          }}
        />
        <SortOptions queryParameter={queryParameter} onUpdate={updateSearchParameter} />
      </Box>
    </Collapse>
  );
};
