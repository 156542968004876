
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import FlashlightOnIcon from '@mui/icons-material/FlashlightOn';
import FlashlightOffIcon from '@mui/icons-material/FlashlightOff';
import FlipCameraAndroidRoundedIcon from '@mui/icons-material/FlipCameraAndroidRounded';
import Quagga from '@ericblade/quagga2';

const ScannerContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  "& video": {
    width: '100%',
  },
  "& canvas": {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
  "& #interactive": {
    position: 'relative',
  },
}));

const initialConfiguration = {
  inputStream: {
    type : "LiveStream",
    constraints: {
      facingMode: "environment" // or user
    },
    area: { // defines rectangle of the detection/localization area
      top: "10%",    // top offset
      right: "10%",  // right offset
      left: "10%",   // left offset
      bottom: "10%"  // bottom offset
    },
  },
  locator: {
    patchSize: "large",
    halfSample: true
  },
  decoder: {
    readers : [
      "ean_reader",
    ],
    debug: {
      drawBoundingBox: true,
      showFrequency: true,
      drawScanline: true,
      showPattern: true
    },
  },
  multiple: false,
  locate: true,
  debug: true
  };


export function Scanner( {onDetected} ) {
  const [ devices, setDevices ] = useState([]);
  const [ torch, setTorch ] = useState({
    available: false,
    on: false,
  });
  const [ configuration, setConfiguration ] = useState(initialConfiguration);

  const checkCapabilities = () => {
    let track = Quagga.CameraAccess.getActiveTrack();
    if (typeof track.getCapabilities === 'function') {
      let capabilities = track.getCapabilities();
      if (capabilities.hasOwnProperty('torch')) {
          setTorch({
            ...torch,
            available: true,
          });
      }
    }
  }

  const selectableCameras = () => {
    Quagga.CameraAccess.enumerateVideoDevices()
    .then(function(foundDevices) {
      let availableDevices = [];
      let streamLabel = Quagga.CameraAccess.getActiveStreamLabel();
      foundDevices.forEach(function(device) {
        availableDevices.push({
            'deviceId': device.deviceId,
            'label': device.label,
            'active': streamLabel === device.label
          }
        );
      });
      setDevices(availableDevices);
    });
  }

  const toggleActiveCamera = () => {
    if (devices.length <= 1 ) {
      return;
    }
    let devicesCopy = [...devices];
    let activeCamera = devicesCopy.filter(device => device.active === true);
    let cameras = devicesCopy.filter(device => device.active === false);
    activeCamera[0].active = false;
    cameras[0].active = true;
    cameras.push(activeCamera[0]);

    setDevices(cameras);
    setConfiguration({
      ...configuration,
      inputStream: {
        ...configuration.inputStream,
        constraints: {
          ...configuration.inputStream.constraints,
          deviceId: cameras[0].deviceId,
        }
      }
    });
  }

  const initQuagga = () => {
    Quagga.init(configuration, function(err) {
      if (err) {
        return console.log(err);
      }
      selectableCameras();
      checkCapabilities();
      Quagga.start();
    });

    Quagga.onDetected(onDetected);
    Quagga.onProcessed(function (result) {
        let drawingCtx = Quagga.canvas.ctx.overlay,
        drawingCanvas = Quagga.canvas.dom.overlay;

        if (result) {
            if (result.boxes) {
                drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
                result.boxes.filter(function (box) {
                    return box !== result.box;
                }).forEach(function (box) {
                    Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: "green", lineWidth: 2 });
                });
            }

            if (result.box) {
                Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: "#00F", lineWidth: 2 });
            }

            if (result.codeResult && result.codeResult.code) {
                Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, { color: 'red', lineWidth: 3 });
            }
        }
    });
  }

  useEffect(() => {
    if (torch && torch.available === true) {
      var track = Quagga.CameraAccess.getActiveTrack();
      if (track && typeof track.getCapabilities === 'function') {
        track.applyConstraints({advanced: [{torch: torch}]});
      }
    }
  },[torch]); // eslint-disable-line

  useEffect(() => {
    if (configuration) {
      Quagga.stop();
      initQuagga();
    }
    return () => {
      Quagga.offDetected(onDetected);
      Quagga.stop();
    }
  },[configuration]); // eslint-disable-line

  return (
    <ScannerContainer>
      <div id="interactive" className="viewport">

        <IconButton sx={{ position: 'absolute', bottom: 15, right: 15, color: 'primary.light', zIndex:1000}} onClick={() => setTorch({...torch, on:!torch.on})} disabled={!torch.available}>
          {torch ? <FlashlightOnIcon /> : <FlashlightOffIcon />}
        </IconButton>

        <IconButton sx={{ position: 'absolute', bottom: 60, right: 15, color: 'primary.light', zIndex:1000}} onClick={toggleActiveCamera} disabled={devices.length === 0}>
          <FlipCameraAndroidRoundedIcon />
        </IconButton>

      </div>
    </ScannerContainer>
  )
}
