import { useState, useEffect } from "react";
import { Typography } from "@mui/material";

import { BookItem, EmptyBookItem } from "../BookItem";

import { getBooks } from "../../../api/endpoints";
import { useApi } from "../../../hooks";

export function RecentlyAddedBooks({ count }) {
  const [books, setBooks] = useState([]);
  const { data: booksData, executeRequest, cancelRequest } = useApi(getBooks);

  useEffect(() => {
    booksData && setBooks(booksData);
  }, [booksData]);

  useEffect(() => {
    executeRequest({
      size: count,
      page: 0,
      sort: 'id,desc'
    });
    return () => {
      cancelRequest();
    }
  }, []); //eslint-disable-line  

  return (
    <>
      <Typography component="div" variant="overline">
        Zuletzt hinzugefügt
      </Typography>

      {
        books.length === 0
          ? <EmptyBookItem title="Nichts da..." subTitle="Wie seltsam?" elevation={0} variant="outlined" sx={{ background: 'transparent' }} />
          : books.map(book => (
            <BookItem book={book} key={book?.id} />
          ))
      }

    </>
  );
}