import React from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";

import { DetailNavigation } from '../Navigation';

export function Error() {
  const navigate = useNavigate();


  return (
    <div>
      <DetailNavigation>
        <IconButton aria-label="back" onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
      </DetailNavigation>

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}>

        <Grid item>
          <Typography variant="h5" component="h1" sx={{ pl: 2 }}>
            Ups
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="body1" component="div" sx={{ pt: 2, pl: 2 }}>
            hier scheint nichts zu sein!?
          </Typography>
          <Button variant="contained" startIcon={<ArrowBackIcon />} onClick={() => navigate(-1)} sx={{ mt: 2, ml:2}}>
            Zurück zur letzten Ansicht
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
