import Image from 'mui-image';
import { CircularProgress } from '@mui/material';

export function BookCover({ book }) {
  const FALLBACK_IMAGE = "/assets/missing_image.svg";

  return (
    <Image
      src={book?.coverUrl ? book.coverUrl : FALLBACK_IMAGE}
      width="100%"
      fit="cover"
      duration={1000}
      easing='cubic-bezier(0.7, 0, 0.6, 1)'
      showLoading={<CircularProgress color="secondary" thickness={1} />}
      errorIcon={<img alt="Kein Buchcover" src={FALLBACK_IMAGE} />}
      shift={null}
      distance="100px"
      shiftDuration={600}
      bgColor="inherit"
      alt={book?.title}
    />
  );
}