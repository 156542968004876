import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import {useApi} from '../../hooks';
import { createAuthor, getAuthors } from '../../api/endpoints';


const filter = createFilterOptions();

export default function AuthorInput( {addAuthorToList}) {
  const { data: createdAuthor, executeRequest: executeCreation } = useApi(createAuthor);
  const { loading: fetching, data: fetchedAuthors, executeRequest: executeFetch, cancelRequest: cancelFetch } = useApi(getAuthors);


// eslint-disable-next-line
const [value, setValue] = useState(null);
const [inputValue, setInputValue] = useState('');
const [open, setOpen] = useState(false);
const [visible, toggleVisible] = useState(false);
const [options, setOptions] = useState([]);
const loading = open && options && options.length === 0;

useEffect(() => {
  if (createdAuthor) {
    addAuthorToList(createdAuthor);
    toggleVisible(false);
  }
}, [createdAuthor]);  // eslint-disable-line react-hooks/exhaustive-deps

const handleAddNewAuthor = () => {
  executeCreation(
    {},
    {
      firstName:dialogValue.firstName,
      lastName: dialogValue.lastName
    },
  )
};

const onChangeFirstName = (event) => {
  let value = event.target.value;
  setDialogValue(prevDialogValue => {
    return {
      ...prevDialogValue,
      firstName: value
    }
  });
}

const onChangeLastName = (event) => {
  let value = event.target.value;
  setDialogValue(prevDialogValue => {
    return {
      ...prevDialogValue,
      lastName: value
    }
  });
}

const handleHide = () => {
  setDialogValue({
    firstName: '',
    lastName: '',
  });

  toggleVisible(false);
};

const [dialogValue, setDialogValue] = useState({
 firstName: '',
 lastName: '',
});

useEffect(() => {
  if (fetchedAuthors) {
    setOptions(fetchedAuthors);
  }
}, [fetchedAuthors]);

useEffect(() => {

  if (!loading) {
    return undefined;
  }

  executeFetch({
    size: 500,
    page: 0,
    sort: 'lastName,asc'
  });

  return () => {
    cancelFetch();
  };
}, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

useEffect(() => {
  if (!open) {
    setOptions([]);
  }
}, [open]);

return (
  <React.Fragment>
    {!visible && (
      <Autocomplete
        sx={{ mb: 1, popper: {zIndex: 2001,}}}
        id="author"
        value={value}
        inputValue={inputValue}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onInputChange={(event, value) => {
          setInputValue(value);
        }}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setTimeout(() => {
              toggleVisible(true);
              setDialogValue({
                firstName: newValue.substr(0, newValue.indexOf(' ')),
                lastName: newValue.substr(newValue.indexOf(' ')+1),
              });
            });
          } else if (newValue && newValue.inputValue) {
            toggleVisible(true);
            setDialogValue({
              firstName: newValue.inputValue.substr(0, newValue.inputValue.indexOf(' ')),
              lastName: newValue.inputValue.substr(newValue.inputValue.indexOf(' ')+1),
            });
          } else {
            newValue && addAuthorToList(newValue);
            setInputValue('');
          }
        }}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.title;
          }
          return option.firstName + ' ' + option.lastName;
        }}
        clearOnBlur
        selectOnFocus
        freeSolo
        fullWidth
        margin="dense"
        handleHomeEndKeys
        options={options}
        loading={loading}
        filterOptions={(options, params) => {
            const filtered = filter(options, params);

            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                title: `Add "${params.inputValue}"`,
              });
            }

            return filtered;
          }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            margin="dense"
            color="secondary"
            label="Autor(en) hinzufügen"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {fetching ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    )}
    {visible && (
      <div>
        <TextField
          required
          id="author-firstname"
          label="Vorname"
          value={dialogValue.firstName}
          size="small"
          variant="outlined"
          margin="dense"
          sx={{ ml: 1, mb: 1, pr: 1, popper: {zIndex: 2001,}}}
          color="secondary"
          onChange={onChangeFirstName}
        />

        <TextField
          required
          id="author-lastname"
          label="Nachname"
          value={dialogValue.lastName}
          size="small"
          variant="outlined"
          margin="dense"
          sx={{ ml: 1, mb: 1, pr: 1, popper: {zIndex: 2001,}}}
          color="secondary"
          onChange={onChangeLastName}
        />

          <IconButton
            aria-label="confirm"
            sx={{ mt: 1 }}
            onClick={handleAddNewAuthor}
            size="large">
           <CheckIcon fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="clear"
            sx={{ mt: 1 }}
            onClick={handleHide}
            size="large">
            <ClearIcon fontSize="small" />
          </IconButton>
      </div>
    )}

  </React.Fragment>
);
};
