import React from 'react';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';

const StyledRoot = styled('div')({
});


export default function Steps({ children, activeStep, wizardSteps }) {
  return (
    <StyledRoot sx={{ pl: 2 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {wizardSteps.map((step) => {
          return (
            <Step key={step.number}>
              <StepLabel>{step.title}</StepLabel>
              <StepContent>
                {children}
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
    </StyledRoot>
  );
}
