import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

import { RecentlyViewedBooks } from "./RecentlyViewedBooks";
import { RecentlyAddedBooks } from './RecentlyAddedBooks';

export function Sidebar() {
  const theme = useTheme();

  return (
    <Stack
      spacing={1}
      sx={{
        mt: 8,
        mx: 2,
        overflow: 'hidden',
        '&:hover': { overflow: 'auto' },
        height: `calc(100vh - ${theme.spacing(16)})`,
        '::-webkit-scrollbar': { width: '5px' },
        '::-webkit-scrollbar-track': { backgroundColor: 'primary.light' },
        '::-webkit-scrollbar-thumb': { backgroundColor: 'secondary.light' },
        '::-webkit-scrollbar-thumb::hover': { backgroundColor: 'secondary.main' },
      }}>
      <RecentlyViewedBooks count={4} />
      <RecentlyAddedBooks count={4} />
    </Stack>
  );
}