import React, { useContext, createContext } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';

const SearchContext = createContext(null);

export const useSearch = () => {
  const [ queryParameter, setQueryParameter ] = useContext(SearchContext);

  const updateQueryParameter = (key, value) => {
    setQueryParameter({
      ...queryParameter,
      [key]: value
    })
  }

  const updateQueryParameters = (parameters) => {
    setQueryParameter({
      ...parameters
    })
  }

  return {
    queryParameter,
    updateQueryParameter,
    updateQueryParameters
  };
}

export const SearchProvider = ({ children }) => {
  const [queryParameter, setQueryParameter] = useLocalStorage('queryParams',{
    offset: 0,
    pageSize: 20,
    pageNumber: 0,
    sortDirection: 'asc',
    sortKey: 'title',
    searchString: '',
    filter: []
  });
  
  return (
    <SearchContext.Provider value={[queryParameter, setQueryParameter]}>
      {children}
    </SearchContext.Provider>
  )
}
