
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';

import { NumericIcon } from '../../../theme/Numeric';
import FormDrawer from '../../FormDrawer/FormDrawer';
import EditAuthorsForm from '../EditFormComponents/EditAuthorsForm';
import EditIsbnForm from '../EditFormComponents/EditIsbnForm';

const Authors = ({ authors }) => {
  if (authors?.length > 0) {
    return (
      <div>
        {authors?.map((author) =>
        (<div key={author.id}>
          {author.firstName + ' ' + author.lastName}
        </div>)
        )}
      </div>
    )
  } else {
    return (<div>-</div>);
  }
}

export function BookDetails({ book, editMode, callback }) {

  return (
    <List sx={{ mb: 10, maxWidth:'95%', pl:1 }}>
      <ListItem
        sx={{ alignItems: 'flex-start' }}
        secondaryAction={editMode && <FormDrawer form={EditAuthorsForm} object={book} onEvent={callback} />}
      >
        <ListItemIcon sx={{ mt: 0.5 }}>
          <PeopleOutlinedIcon />
        </ListItemIcon>
        <ListItemText
          sx={{ mt: 0 }}
          primary={<Authors authors={book.authors} />}
          secondary={book.authors?.length === 1 ? 'Autor' : ' Autoren'}
        />
      </ListItem>
      <Divider variant="inset" />
      {
        book?.isbn13 && (
          <ListItem
            secondaryAction={editMode && <FormDrawer form={EditIsbnForm} object={book} onEvent={callback} />}
            sx={{ alignItems: 'flex-start' }}
          >
            <ListItemIcon sx={{ mt: 0.2 }}>
              <NumericIcon />
            </ListItemIcon>
            <ListItemText
              sx={{ mt: 0 }}
              primary={book.isbn13}
              secondary="ISBN-13"
            />
          </ListItem>
        )
      }
      {
        book?.isbn10 && (
          <ListItem
            secondaryAction={false && <FormDrawer form={EditIsbnForm} object={book} onEvent={callback} />}
            sx={{ alignItems: 'flex-start' }}
          >
            <ListItemIcon sx={{ mt: 0.2 }}>
              <NumericIcon />
            </ListItemIcon>
            <ListItemText
              sx={{ mt: 0 }}
              primary={book.isbn10}
              secondary="ISBN-10"
            />
          </ListItem>
        )
      }
    </List>
  );
}