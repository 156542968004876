import * as React from 'react';
import { NavLink, useLocation, matchPath, useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import MuiDrawer from '@mui/material/Drawer';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import AddIcon from '@mui/icons-material/Add';
import BarcodeScanner from '../BarcodeScanner';
import BookSearchBar from '../BookSearchBar';
import { Sidebar } from '../Sidebar';

import { useAuth0 } from '@auth0/auth0-react';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: theme.palette.secondary.light,
  border: 'none',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: theme.palette.secondary.light,
  border: 'none',
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  marginLeft: `calc(${theme.spacing(7)} + 1px)`,
  width: `calc(100% - calc(${theme.spacing(7)}+1px))`,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

//use hover here to change color of all children instead of hover on each children...
const StyledNavLink = styled(NavLink)(({ theme }) => ({
  textDecoration: 'none',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(0.5),
  margin: 'auto',
  '&:hover *': {
    color: theme.palette.primary.main,
  },
}));

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "isActive",
})(({ theme, isActive }) => ({
  color: isActive ? theme.palette.primary.main : theme.palette.secondary.dark, //#d3d3d3
  margin: 'auto',
  paddingTop: 0,
  paddingBottom: 0,
}));

const StyledNavTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isActive",
})(({ theme, isActive }) => ({
  color: isActive ? theme.palette.primary.main : theme.palette.secondary.dark,
  margin: 'auto',
}));

const NavLinkItem = (props) => {
  const { children, path, title } = props;

  return (
    <StyledNavLink
      to={path}
      children={({ isActive }) => {
        return (
          <>
            <StyledIconButton isActive={isActive}>
              {children}
            </StyledIconButton>
            <StyledNavTitle isActive={isActive} variant="caption" component="div" gutterBottom>
              {title}
            </StyledNavTitle>
          </>
        )
      }}
    />
  )
}

export default function DesktopNavigation(props) {
  const { children } = props;
  const [drawerOpen] = React.useState(false); //[drawerOpen, setDrawerOpen]
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth0();
  const isBooksPage = matchPath({ path: "/", exact: true }, location.pathname);

  /*const handleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };*/

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        open={drawerOpen}
        position="fixed"
        elevation={0}
      >
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mx: 2,
              display: { md: 'flex' },
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            librius
          </Typography>
          <Box sx={{ flexGrow: 1, display: { md: 'flex' } }}>
            {isBooksPage && <BookSearchBar />}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={user.name}>
              <IconButton onClick={() => navigate(`/profile`)} sx={{ p: 0 }}>
                <Avatar sx={{backgroundColor: 'secondary.main'}} alt={user.name}>{user.name.substring(0,1)}</Avatar>
              </IconButton>
            </Tooltip>
          </Box>

        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={drawerOpen}>
        <Stack spacing={2} sx={{ mt: 5 }}>

          <NavLinkItem path="/" title="Bücher">
            <AutoStoriesOutlinedIcon />
          </NavLinkItem>

          <NavLinkItem path="/authors" title="Autoren">
            <PeopleOutlinedIcon />
          </NavLinkItem>

          <NavLinkItem path="/books/add" title="Neu">
            <AddIcon />
          </NavLinkItem>

          <BarcodeScanner />
        </Stack>

      </Drawer>

      <Box component="main" sx={{ flexGrow: 1, backgroundColor: 'primary.main', minHeight: '100vh', display: 'flex' }} id="main-content">
        <DrawerHeader />
        <Box sx={{ flex: 1, pl: 2, backgroundColor: '#fff', borderRadius: '30px 30px 0 0', mt: 8, pt: 2 }} id="content-container">
          {children}
        </Box>
        <Box sx={{ flex: 0.2 }} id="sidebar-right">
          <Sidebar />
        </Box>
      </Box>
    </Box >
  );
}
