import React from 'react';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import { ClickAwayListener } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useSearch } from '../../utils/SearchProvider';
import { OptionsButton, OptionsButtonBar } from './BookSearchBar.parts';

export default function BookSearchBar() {
  const { queryParameter, updateQueryParameters: updateSearchParameter } = useSearch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [settingsActive, setSettingsActive] = React.useState(false);

  const submitHandler = () => (event) => {
    event.preventDefault();
  };

  const clearHandler = () => (event) => {
    if (queryParameter.searchString.length > 0) {
      updateSearchParameter({
        ...queryParameter,
        searchString: '',
        pageNumber: 0
      })
    }
  };

  const toggleSearchOptions = () => {
    setSettingsActive(!settingsActive);
  }

  const handleClickAway = () => {
    setSettingsActive(false);
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Paper
        id="book-search-bar"
        elevation={settingsActive ? 4 : 0}
        variant={settingsActive ? 'elevation' : 'outlined'}
        component="form"
        onSubmit={submitHandler()}
        sx={{
          m: '8px',
          p: '0px 4px',
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          backgroundColor: 'secondary.light',
          borderColor: 'secondary.light',
          ...(!isMobile && {
            position: 'absolute',
            top: '6px',
            left: 100,
          }),
        }}
      >
        <IconButton aria-label="search" onClick={clearHandler()}>
          {queryParameter.searchString.length > 0 ? <ClearIcon /> : <SearchIcon />}
        </IconButton>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Suchen..."
          inputProps={{ 'aria-label': 'suche' }}
          value={queryParameter.searchString}
          onChange={(event) => updateSearchParameter({
            ...queryParameter,
            searchString: event.target.value,
            pageNumber: 0
          })}

        />
        <OptionsButton onClick={toggleSearchOptions} active={settingsActive} />
        <Box
          id='break'
          sx={{
            flexBasis: '100%',
            height: 0
          }}
        />
        <OptionsButtonBar visible={settingsActive} queryParameter={queryParameter} updateSearchParameter={updateSearchParameter} />
      </Paper>
    </ClickAwayListener>
  );

}
