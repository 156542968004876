import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EditOffOutlinedIcon from '@mui/icons-material/EditOffOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import { useNavigate } from "react-router-dom";

import ConfirmationDialog from "../../ConfirmationDialog";
import { FavoriteIndicator } from "../../Favorites/FavoriteIndicator";

import { useDeviceParameter } from "../../../hooks";
import { usePermissions } from "../../../utils/PermissionsProvider";

export function BookOptions({ book, updateCallback, deleteCallback, loading, deleting, toggleCallback, editMode }) {
  const { isMobile } = useDeviceParameter();
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();


  if (isMobile) {
    return (
      <>

        <IconButton aria-label="back" onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>

        <Box sx={{ margin: 'auto' }} />

        <Box sx={{ paddingLeft: 1.5, paddingRight: 1.5 }}>
          <FavoriteIndicator bookId={book?.id} isFavorite={book?.favorite} updateParent={updateCallback} disable={loading} />
        </Box>


        {(hasPermission('delete:books') || hasPermission('manage:books')) &&
          <Box component="div" sx={{ paddingLeft: 1.5, paddingRight: 1.5 }}>
            <ConfirmationDialog
              title="Buch löschen"
              text={`"${book?.title}" wirklich löschen?`}
              buttonVariant="icon"
              icon={<DeleteOutlinedIcon />}
              executeFunction={deleteCallback}
              processing={deleting} />
          </Box>
        }

        {(hasPermission('update:books') || hasPermission('manage:books')) &&
          <IconButton aria-label="edit" onClick={toggleCallback} sx={{ paddingLeft: 1.5, paddingRight: 1.5 }}>
            {editMode
              ? <EditOffOutlinedIcon />
              : <EditOutlinedIcon />
            }
          </IconButton>
        }

      </>
    );
  }

  return (
    <Paper>
      <Grid item container direction="row">

        <Grid item>
          <Box sx={{ float: 'right' }}>
            <FavoriteIndicator
              bookId={book?.id}
              isFavorite={book?.favorite}
              updateParent={updateCallback}
              disable={loading}
            />
          </Box>
        </Grid>

        <Grid item>
          <Box sx={{ float: 'right' }}>
            {(hasPermission('delete:books') || hasPermission('manage:books')) &&
              <ConfirmationDialog
                title="Buch löschen"
                text={`"${book?.title}" wirklich löschen?`}
                buttonVariant="icon"
                icon={<DeleteOutlinedIcon color="secondary" />}
                executeFunction={deleteCallback}
                processing={deleting}
              />
            }
          </Box>
        </Grid>

        <Grid item>
          <Box sx={{ float: 'right' }}>
            {(hasPermission('update:books') || hasPermission('manage:books')) &&
              <IconButton aria-label="edit" onClick={toggleCallback} variant="outlined" color="secondary">
                {editMode
                  ? <EditOffOutlinedIcon />
                  : <EditOutlinedIcon />
                }
              </IconButton>
            }
          </Box>
        </Grid>

      </Grid>
    </Paper>
  );
}