import React from 'react';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from 'react-router-dom';
import { useDeviceParameter } from '../../hooks';

const StyledList = styled(List)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  paddingTop: theme.spacing(0),
  paddingBottom: theme.spacing(10),
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
}));

export const InfiniteBookList = (props) => {
  const { next, hasMore, loader, children } = props;
  const { isMobile } = useDeviceParameter();

  return (
    <StyledList>
      <InfiniteScroll
        dataLength={children.length}
        next={next}
        hasMore={hasMore}
        loader={loader}
        scrollableTarget={!isMobile ? "books-list-container" : undefined}
      >
        {children}
      </InfiniteScroll>
    </StyledList>
  )
}

export const BookListItem = (props) => {
  const { book } = props;
  const navigate = useNavigate();

  return (
    <StyledListItem disableGutters onClick={() => navigate('/books/' + book?.id)}>
      <ListItemAvatar>
        <Avatar
          variant="rounded"
          sx={{
            bgcolor: stringToColor(book?.title.substring(0, 4))
          }}
        >
          {book?.title.substring(0, 1)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={book?.title}
        secondary={book?.authors}
      />
    </StyledListItem>
  );
}

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
