import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { useSnackbar } from 'notistack';
import { useDeviceParameter } from '../../hooks';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function FormDrawer(props) {
  const { form: Form, object, onEvent } = props;
  const [visible, setVisible] = useState(false);
  const [formTitle, setFormTitle] = useState("Bearbeiten");
  const { enqueueSnackbar } = useSnackbar();
  const { isMobile } = useDeviceParameter();

  const toggleVisibility = (newState) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setVisible(newState);
  }

  const updateFormTitle = (formTitle) => {
    setFormTitle(formTitle);
  }

  const handleSuccess = () => {
    setVisible(false); // dirty fix...
    onEvent();
  }

  const showErrorMessage = (message) => {
    enqueueSnackbar(message, {
      variant: 'error',
    });
  };

  if (isMobile) {
    return (
      <>
        <IconButton aria-label="edit" onClick={toggleVisibility(true)} sx={{ paddingLeft: 1.5, paddingRight: 1.5 }}>
          <EditOutlinedIcon />
        </IconButton>

        <Drawer
          open={visible}
          anchor="bottom"
          onClose={toggleVisibility(false)}
          PaperProps={{ sx: { padding: 1.5 } }}
          elevation={24}
        >
          <Typography variant="h5" component="h2" gutterBottom sx={{ mb: 2 }}>
            {formTitle}
          </Typography>
          <Form
            setFormTitle={updateFormTitle}
            object={object}
            onSuccess={handleSuccess}
            onCancel={toggleVisibility(false)}
            onError={showErrorMessage}
          />
        </Drawer>
      </>
    );
  }

  return (
    <>
      <IconButton aria-label="edit" onClick={toggleVisibility(true)} sx={{ paddingLeft: 1.5, paddingRight: 1.5 }}>
        <EditOutlinedIcon />
      </IconButton>

      <Dialog
        open={visible}
        TransitionComponent={Transition}
        onClose={toggleVisibility(false)}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            width: "50%",
            minHeight: '20vh'
          }
        }}
      >
        <DialogTitle>{formTitle}</DialogTitle>
        <DialogContent>
          <Form
            setFormTitle={updateFormTitle}
            object={object}
            onSuccess={handleSuccess}
            onCancel={toggleVisibility(false)}
            onError={showErrorMessage}
          />
        </DialogContent>
      </Dialog>

    </>
  )


}
