import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: `"Exo 2", "Helvetica", "Arial", sans-serif`,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    useNextVariants: true,
  },
  palette: {
    primary: {
      light: '#ffffff',
      main: '#f3f3f3',
      dark: '#c0c0c0',
      textColor: '#000000',
    },
    secondary: {
      light: '#d6dce8',
      main: '#325490',
      dark: '#002c62  ',
      textColor: '#FFFFFF',
    },
    error: {
      light: '#ef9a9a',
      main: '#e57373',
      dark: '#ef5350',
    },
    warning: {
      light: '#ffe0b2',
      main: '#ffcc80',
      dark: '#ffb74d',
    },
  },
});

export default theme;
