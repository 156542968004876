import React, { useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';

import { useSnackbar } from 'notistack';

import { patchFavorites, deleteBookFromFavorites } from '../../api/endpoints';
import { useApi, useDeviceParameter } from '../../hooks';

export function FavoriteIndicator(props) {
  const { bookId, isFavorite, updateParent, disable } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { isMobile } = useDeviceParameter();

  const {
    loading: deleting,
    error: deleteError,
    responseStatus: deleteStatus,
    executeRequest: executeDelete,
    cancelRequest: cancelDelete } = useApi(deleteBookFromFavorites(bookId));

  const {
    loading: patching,
    error: patchError,
    responseStatus: patchStatus,
    executeRequest: executePatch,
    cancelRequest: cancelPatch } = useApi(patchFavorites);

  const handleToggle = () => {
    if (isFavorite) {
      executeDelete();
    } else {
      executePatch(
        {},
        { bookId: bookId }
      );
    }
  }

  useEffect(() => {
    if (deleteError || patchError) {
      enqueueSnackbar('Fehler beim Speichern.', {
        variant: 'error',
      });
    }
  }, [deleteError, patchError]) // eslint-disable-line

  useEffect(() => {
    if (deleteStatus || patchStatus) {
      updateParent();
    } 
  }, [deleteStatus, patchStatus]) // eslint-disable-line

  useEffect(() => {
    return () => {
      cancelDelete();
      cancelPatch();
    }
  }, []); // eslint-disable-line

  return (
    <>
      <IconButton aria-label="favorite" onClick={handleToggle} disabled={deleting || patching || disable} color={isMobile ? undefined : "secondary"}>
        {isFavorite ? <FavoriteOutlinedIcon /> : <FavoriteBorderOutlinedIcon />}
      </IconButton>
    </>
  );
}