import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';

export default function ConfirmationDialog(props) {
  const { buttonText, title, text, buttonVariant  = 'icon', icon, executeFunction, processing} = props;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    executeFunction();
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div>
      { buttonVariant === 'icon' &&
      <IconButton aria-label="delete" onClick={handleClickOpen}>
        {icon}
      </IconButton>
      }

      { buttonVariant === 'button' &&
      <Button variant="outlined" onClick={handleClickOpen}>
        {buttonText ? buttonText : 'Ausführen'}
      </Button>
      }

      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: 'primary.dark' }} onClick={handleCancel}>Abbrechen</Button>
          <Button variant='outlined' color="secondary" onClick={handleClose} autoFocus disabled={processing}>
          {processing && (
            <CircularProgress
              size={24}
              sx={{
                color: 'secondary.dark',
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
