import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

export function BookItem({ book, removeCallback }) {
  const navigate = useNavigate();

  return (
    <Card>
      <CardActionArea onClick={() => navigate('/books/' + book?.id)}>
        <CardContent>
          <Typography variant="body1"><strong>{book?.title}</strong></Typography>
          <Typography variant="subtitle2">
            {book?.authors?.map((author) => author.firstName + ' ' + author.lastName).join(", ")}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export function EmptyBookItem({title, subTitle, ...other}) {
  return(
    <Card {...other}>
      <CardContent>
          <Typography variant="body1"><strong>{title}</strong></Typography>
          <Typography variant="subtitle2">
            {subTitle}
          </Typography>
        </CardContent>
    </Card>
  );
}