import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Slide from '@mui/material/Slide';
import { useAuth0 } from '@auth0/auth0-react';
import { SnackbarProvider } from 'notistack';
import { ProtectedRoute, routes } from './components/Routes'
import Navigation from './components/Navigation';
import LandingPage from './components/LandingPage';
import LoadingIndicator from './components/LoadingIndicator';
import { ScannerProvider } from './components/BarcodeScanner';
import { PermissionsProvider } from './utils/PermissionsProvider';
import { SearchProvider } from './utils/SearchProvider';
import { RecentlyViewedBooksProvider } from './components/Sidebar/RecentlyViewedBooks';
export default function App() {

  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (!isAuthenticated) {
    return <LandingPage />
  }

  return (
    <BrowserRouter>
      <PermissionsProvider>
        <ScannerProvider
          TransitionComponent={Slide}
        >
          <SnackbarProvider
            maxSnack={1}
            preventDuplicate
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <SearchProvider>
              <RecentlyViewedBooksProvider>
                <Navigation>
                  <Routes>
                    {routes.map(({ path, Component }, key) => (
                      <Route path={path} element={<ProtectedRoute component={Component} />} key={key} />
                    ))}
                  </Routes>
                </Navigation>
              </RecentlyViewedBooksProvider>
            </SearchProvider>
          </SnackbarProvider>
        </ScannerProvider>
      </PermissionsProvider>
    </BrowserRouter>
  );
}
