import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import { useAuth0 } from '@auth0/auth0-react';

import { DetailNavigation } from '../Navigation';
import { LibraryList } from '../LibraryList';
import { useDeviceParameter } from '../../hooks/useDeviceParameter';

export function Profile() {
  const { user, logout } = useAuth0();
  const { isMobile } = useDeviceParameter();

  return (
    <div>
      {isMobile && (
        <DetailNavigation>
        </DetailNavigation>
      )}

      <Grid
        item
        container
        direction="column"
        xs={12} sm={6}
        spacing={2}
        sx={{pr:2}}
      >
        <Grid item>
          <Typography variant="h5" component="h1" sx={{ pt: 2, pl: 2 }}>
            {user ? user.name : 'Benutzer'}
          </Typography>

          <List sx={{ mb: 1 }}>
            <ListItem sx={{ alignItems: 'flex-start' }}>
              <ListItemIcon sx={{ mt: 0.5 }}>
                <AlternateEmailOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                sx={{ mt: 0 }}
                primary={user ? user.email : '-'}
                secondary='E-Mail'
              />
            </ListItem>
            <Divider variant="inset" />

          </List>
        </Grid>
        <Grid item>
          <Typography variant="h5" component="h1" sx={{ pl: 2 }}>
            Sammlungen
          </Typography>
          <LibraryList />
        </Grid>
        <Grid item container
          direction="column"
          justifyContent="space-evenly"
          alignItems="space-evenly">
          <Button
            onClick={() => logout()}
            sx={{ mx: 2, my: 1 }}
            color="secondary"
            variant="contained"
            size="small">
            Abmelden
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
