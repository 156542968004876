import React from 'react';

import { InfiniteAuthorList, AuthorListItem } from './AuthorList.parts'

export default function AuthorList(props) {
  const {authors, next, hasMore } = props;

  return(
    <InfiniteAuthorList next={next} hasMore={hasMore} loader={<></>}>
      {authors.map((author, index) => (
        <AuthorListItem key={index} author={author} />
      ))}
    </InfiniteAuthorList>
  );
};
