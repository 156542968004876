import React, { useState, useEffect } from 'react';

import { styled, useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { useSnackbar } from 'notistack';

import AuthorList from '../AuthorList';
import { getAuthors } from '../../api/endpoints';
import { useApi, useDeviceParameter } from '../../hooks';

const Progress = styled(CircularProgress)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
});

export function Authors() {
  const [authors, setAuthors] = useState([]);
  const [queryParameter, setQueryParameter] = useState({
    offset: 0,
    pageSize: 20,
    pageNumber: 0,
    sortDirection: 'asc',
    sortKey: 'lastName',
  });
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [responseMeta, setResponseMeta] = useState({ last: true });
  const { loading, error: getError, data: authorsData, metadata, executeRequest, cancelRequest } = useApi(getAuthors); //loading and error would be available
  const { isMobile } = useDeviceParameter();
  useEffect(() => {
    return () => { cancelRequest(); }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (getError) {
      enqueueSnackbar('Fehler beim Laden.', {
        variant: 'error',
      });
    }
  }, [getError]); // eslint-disable-line

  const updateQueryParameter = (key, value) => {
    setQueryParameter({
      ...queryParameter,
      [key]: value
    })
  };

  useEffect(() => {
    if (authorsData) {
      setAuthors(authors.concat(authorsData));
      setResponseMeta(metadata);
    }
  }, [authorsData]);   // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (queryParameter) {
      executeRequest({
        size: queryParameter.pageSize,
        page: queryParameter.pageNumber,
        sort: queryParameter.sortKey + ',' + queryParameter.sortDirection
      });
    }
  }, [queryParameter]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid
      container
      direction={isMobile ? 'column' : 'row'}
      justifyContent="flex-start"
      spacing={0}
    >

      <Grid
        id="authors-list-container"
        item
        container
        sm={6}
        sx={{
          ...(!isMobile && {
            overflow: 'hidden',
            '&:hover': { overflow: 'auto' },
            height: `calc(100vh - ${theme.spacing(10)})`,
            '::-webkit-scrollbar': { width: '5px' },
            '::-webkit-scrollbar-track': { backgroundColor: 'primary.light' },
            '::-webkit-scrollbar-thumb': { backgroundColor: 'secondary.light' },
            '::-webkit-scrollbar-thumb::hover': { backgroundColor: 'secondary.main' },
          })
        }}
      >

        <AuthorList
          authors={authors}
          next={() => updateQueryParameter('pageNumber', queryParameter.pageNumber + 1)}
          hasMore={!responseMeta.last}
          pageSize={queryParameter.pageSize}
          id="authors-list"
        />

        {!responseMeta?.last && (
          <Grid container item xs={12} alignItems="center" justifyContent="center">
            <Button
              color="secondary"
              variant="outlined"
              size="small"
              onClick={() => updateQueryParameter('pageNumber', queryParameter.pageNumber + 1)}
            >
              {(loading) && <Progress size={24} />}
              Weitere laden
            </Button>
          </Grid>
        )}

        {(authors?.length === 0 && loading) && (
          <Grid container item xs={12} alignItems="center" justifyContent="center">
            <Grid item>
              <CircularProgress
                color="secondary"
                size="4rem"
                thickness={1}
              />
            </Grid>
          </Grid>
        )}
      </Grid>

    </Grid>
  );
}
