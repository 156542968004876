import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const CenterBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  flexGrow: 1,
  paddingTop: theme.spacing(2),
}));

export default function LoadingIndicator() {

  return (
    <CenterBox>
      <CircularProgress
        color="secondary"
        size="7rem"
        thickness={2}
        />
    </CenterBox>
  );
}
