import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { patchBook } from '../../../api/endpoints';
import { useApi } from '../../../hooks';
import { isValidTitle } from '../../../utils/Validation';

const DEFAULT_ERROR = {
  hasError: false,
  helperText: ' '
};

export default function EditTitleForm(props) {
  const {setFormTitle, object: book, onSuccess, onCancel, onError, children} = props;
  const [newTitle, setNewTitle] = useState(book ? book.title : "");
  const [error, setError] = useState(DEFAULT_ERROR);

  const { 
    loading: patching, 
    error: patchError, 
    data: patchData, 
    responseStatus: patchResponseStatus, 
    executeRequest: executePatch, 
    cancelRequest: cancelPatch
  } = useApi(patchBook(book?.id));

  const handleFieldChange = (event) => {
    setNewTitle(event.target.value);
  }

  const handleSave = (event) => {
    event.preventDefault();
    executePatch(
      {},
      {title: newTitle}
    );
  }

  const handleCancel = () => {
    setNewTitle(book?.title);
    onCancel();
  }

  useEffect (() => {
    setError(DEFAULT_ERROR);
    if (!isValidTitle(newTitle)) {
      setError({
        hasError: true,
        helperText: 'Es sollte schon einen Titel geben...'
      });
    }
  }, [newTitle]); // eslint-disable-line

  useEffect(() => {
    if(patchData) {
      onSuccess();
    }
  }, [patchData]); // eslint-disable-line

  useEffect(() => {
    if(patchError) {
      onError(`Fehler beim Speichern der Änderung. ${patchResponseStatus ? '('+patchResponseStatus+')' : ''}`);
    }
  }, [patchError]); // eslint-disable-line

  useEffect(() => {
    setFormTitle("Buchtitel bearbeiten")
    return () => {
      cancelPatch();
    } 
  }, []); // eslint-disable-line

  return (
    <> 
      {children}
      <form noValidate autoComplete="off" onSubmit={handleSave}>
        <TextField
          id="book-title"
          required
          label={`Titel (war: ${book?.title})`}
          fullWidth
          value={newTitle}
          onChange={handleFieldChange}
          error={error?.hasError}
          helperText={error?.helperText}
          size="small"
          variant="outlined"
          margin="dense"
          color="secondary"
          sx={{ my:1  }}
        />
      </form>
      <Grid container
        direction="column"
        justifyContent="space-evenly"
        alignItems="space-evenly"
      >

        <Button
          autoFocus
          variant="contained"
          color="secondary"
          sx={{ my:1  }}
          onClick={handleSave}
          disabled={patching || error?.hasError}
        >
          {(patching) && (
            <CircularProgress
              size={24}
              sx={{
                color: 'secondary.dark',
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
          Speichern
        </Button>

        <Button
          sx={{ my:1  }}
          color="primary"
          variant="contained"
          disableElevation
          disabled={patching}
          onClick={handleCancel}
        >
          Abbrechen
        </Button>
      </Grid>
    </>
  )
}