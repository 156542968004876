import React, { useState, useEffect } from 'react';
import Steps from './Steps';
import BookIsbn from './BookIsbn';
import BookTitle from './BookTitle';
import BookAuthors from './BookAuthors';
import BookSummary from './BookSummary';

export default function BookWizard( {handleClose, bookData, updateBookData, reset} ) {

  const [activeStep, setActiveStep] = useState(0);

  const closeDialog = () => {
    handleClose();
  }

  const handleNext = (data) => {
    updateBookData(data);

    if (activeStep < 3) {
      setActiveStep((prevActiveStep) => {
        return prevActiveStep + 1;
      });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep === 0) {
        closeDialog();
      }
      return prevActiveStep - 1;
    });
  };

  useEffect (() => {
      if (activeStep && activeStep === 0) {
        setActiveStep(0);
      }
    // eslint-disable-next-line
  }, [activeStep]);

  useEffect (() => {
      if (reset) {
        setActiveStep(0);
      }
    // eslint-disable-next-line
  }, [reset]);


  const wizardSteps = () => [
    {
      number: 0,
      final: false,
      title:'ISBN-13' + (bookData?.isbn13 ? ': ' + bookData.isbn13 : ''),
    },
    {
      number: 1,
      final: false,
      title: 'Titel' + (bookData?.title ? ': ' + bookData.title : ''),
    },
    {
      number: 2,
      final: false,
      title: 'Autoren',
    },
    {
      number: 3,
      final: true,
      title: 'Zusammenfassung',
    },
  ];

  const renderActiveStep = () => {
    switch (activeStep) {
      case 0:
        return <BookIsbn handleNext={handleNext} handleBack={handleBack} book={bookData} />;
      case 1:
        return <BookTitle handleNext={handleNext} handleBack={handleBack} book={bookData} />;
      case 2:
        return <BookAuthors handleNext={handleNext} handleBack={handleBack} book={bookData} />;
      default:
        return <BookSummary handleBack={handleBack} book={bookData} />;
    }
  };

  //const getActiveStep = () => wizardSteps().find((step) => step.number === activeStep);

  return (
    <>
      <Steps activeStep={activeStep} wizardSteps={wizardSteps()} >
        {renderActiveStep()}
      </Steps>

    </>
  )
}
