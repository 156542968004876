import { Typography } from "@mui/material";

import { BookItem, EmptyBookItem } from "../BookItem";
import { useRecentlyViewedBooks } from "./RecentlyViewedBooksProvider";

export function RecentlyViewedBooks({ count }) {
  const { books, removeBook } = useRecentlyViewedBooks();

  return (
    <>
      <Typography component="div" variant="overline">
        Zuletzt angesehen
      </Typography>

      {
        books.length === 0
          ? <EmptyBookItem title="Nichts da..." subTitle="Klicke doch mal ein Buch an. ;)" elevation={0} variant="outlined" sx={{ background: 'transparent' }} />
          : books.map(book => (
            <BookItem book={book} removeCallback={removeBook} key={book?.id} />
          ))
      }

    </>
  );
}