import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';

import BookWizard from './BookWizard';
import { getLibraries, createBook, patchAuthorBookRelation } from '../../api/endpoints';
import { useApi, useTimeout } from '../../hooks';

export function NewBook() {
  const [complete, setComplete] = useState(false);
  const [bookData, setBookData] = useState({});
  const [ reset, setReset ] = useState(false);
  const [ redirectTimeout, setRedirectTimeout ] = useState(null);
  const { loading: loadingLibraries, data: libraries, executeRequest: loadLibraries, cancelRequest: cancelLibraries } = useApi(getLibraries);
  const { loading: creating, error: creationError, data: createdBook, executeRequest: executeCreation, cancelRequest: cancelCreation } = useApi(createBook);
  const { loading: patching, error: patchError, executeRequest: executePatch, cancelRequest: cancelPatching } = useApi(patchAuthorBookRelation(null));
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    return () => {
      cancelLibraries();
      cancelCreation();
      cancelPatching();
    }
  }, []); // eslint-disable-line

  const resetForm = () => {
    setBookData({});
    setReset(true);
  };

  useTimeout(() => {
    if (createdBook) {
      navigate(`/books/${createdBook.id}`);
    }
  }, redirectTimeout);

  useEffect (() => {
    if (creationError || patchError) {
      setRedirectTimeout(null);
      enqueueSnackbar('Fehler beim Speichern.', {
        variant: 'error',
      });
    }
  }, [creationError, patchError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect (() => {
      if (createdBook) {
        bookData.authors.forEach((author) => {
          executePatch(
            {},
            {
              bookIds: [createdBook.id],
            },
            patchAuthorBookRelation(author.id)
          )
        });
        setRedirectTimeout(1000);
      }
  }, [createdBook]); // eslint-disable-line react-hooks/exhaustive-deps

  const save = () => {
    executeCreation(
      {},
      {
        title: bookData.title,
        ...(bookData.isbn13 && {isbn13 : bookData.isbn13}),
        ...(bookData.isbn10 && {isbn10 : bookData.isbn10})
      }
    );
  }

  const handleBookDataChange = (updatedBook) => {
    setBookData(updatedBook);
    if (reset) {
      setReset(false);
    }
  }

  useEffect(() => { //check for completeness
    if(bookData) {
      if (bookData?.title && bookData?.authors?.length > 0) {
        setComplete(true);
      } else {
        setComplete(false);
      }
    }
  }, [bookData]);

  useEffect (() => {
      loadLibraries();
  }, []); // eslint-disable-line

  return (
    <Grid
    item
      container
      direction="column"
      xs={12} sm={9} md={6}
      justifyContent="space-between"
      sx={{ height: '85vh'}}
    >
      <Grid item>
        <Typography variant="h5" component="h1" sx={{ pt: 2, pl: 2 }}>
          Neues Buch hinzufügen
        </Typography>
        <Typography variant="overline" component="h2" sx={{ pl: 2 }}>
          {libraries ? libraries[0]?.name : ''}
        </Typography>
        <BookWizard handleClose={resetForm} bookData={bookData} updateBookData={handleBookDataChange} reset={reset}/>
      </Grid>

      <Grid item container
        direction="column"
        justifyContent="space-evenly"
        alignItems="space-evenly">
        <Button
          autoFocus
          variant="contained"
          color="secondary"
          sx={{ mx:2, my:1  }}
          onClick={save}
          disabled={!complete || creating || patching || loadingLibraries || redirectTimeout !== null }>

          {(creating || patching || redirectTimeout !== null ) && (
            <CircularProgress
              size={24}
              sx={{
                color: 'secondary.dark',
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}

          {redirectTimeout === null ? 'Speichern' : 'Weiterleitung...'}

        </Button>

        <Button
          sx={{ mx:2, my:1  }}
          onClick={resetForm}
          color="primary"
          variant="contained"
          disableElevation
          disabled={creating || patching || loadingLibraries || redirectTimeout !== null }
        >
          Abbrechen
        </Button>
      </Grid>
    </Grid>
  );
}
