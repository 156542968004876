import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';

import { isValidIsbn10, isValidIsbn13 } from '../../../utils/Validation';
import { getBookByIsbn } from '../../../api/endpoints';
import { useApi } from '../../../hooks';
import { useScanner } from '../../BarcodeScanner';


const StepButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginRight: theme.spacing(1),
}));

const Progress = styled(CircularProgress)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
});

export default function BookIsbn({book, handleNext, handleBack}) {

  const [ isbn, setIsbn ] = useState(book?.isbn ? book.isbn : '');
  const [ error, setError ] = useState({});
  const [ valid, setValid ] = useState(false);
  let { scannedValue, onChange } = useScanner();
  const { enqueueSnackbar } = useSnackbar();
  const { loading, data: existingBook, executeRequest } = useApi(getBookByIsbn(book.isbn)); //error and metadata would be available


  const submitData = () => {
    handleNext({
      ...book,
      ...(isValidIsbn10(isbn) && {isbn10 : isbn}),
      ...(isValidIsbn13(isbn) && {isbn13 : isbn}),
    });
  }

  const handleChange = (event) => {
    setIsbn(event.target.value);
  }

  const handleChangeFromScanner = ( scannedIsbn ) => {
    console.debug(`1. Scanned ${scannedIsbn}`);
    scannedIsbn.length === 0 ? setValid(false) : setIsbn(scannedIsbn);
  }

  useEffect (() => {
    if (existingBook) {
      console.debug(`5. Processing received book with ISBN ${existingBook?.isbn13}`);
      book.title = '';
      book.authors = '';
      if (existingBook?.id !== null) {
        console.debug(`6. Fetched book with ISBN ${existingBook?.isbn13} exists`);
        setError({
          hasError: true,
          helperText: 'Buch existiert bereits.'
        });
        enqueueSnackbar('Buch existiert bereits.', {
          variant: 'warning',
        });
      }

      if(existingBook?.id === null) {
        console.debug(`6. Fetched book with ISBN ${existingBook?.isbn13} does not exist`);
        if(existingBook.title !== null) {
          console.debug(`7. Use title from book with ISBN ${existingBook?.isbn13}`);
          book.title = existingBook.title
        }

        if (existingBook.authors.length > 0) {
          console.debug(`8. Use authors from book with ISBN ${existingBook?.isbn13}`);
          book.authors = existingBook.authors;
        }
        enqueueSnackbar(`${existingBook?.title} in der OpenLibrary gefunden.`, {
          variant: 'info',
        });
      }
      console.debug('9. %o', existingBook);
    }
  }, [existingBook]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect (() => {
    if(valid) {
      console.debug(`4. Fetching book with ISBN ${isbn}`);
      executeRequest({}, {}, getBookByIsbn(isbn));
    }
  }, [valid]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect (() => {
    if (isbn) {
      console.debug(`2. Checking ${isbn}`);
      setError({});
      setValid(false);
      if (isbn.length !== 10 && isbn.length !== 13) {
        console.debug(`3. ISBN ${isbn} is too short/long: ${isbn.length}`);
        setValid(false);
      } else if (isbn.length === 13 && isValidIsbn13(isbn)) {
        console.debug(`3. ISBN ${isbn} is a valid ISBn-13`);
        setValid(true);
        setError({});
      } else if (isbn.length === 10 && isValidIsbn10(isbn)) {
        console.debug(`3. ISBN ${isbn} is a valid ISBn-10`);
        setValid(true);
        setError({});
      } else {
        console.debug(`3. ISBN ${isbn} is invalid`);
        setValid(false);
        setError({
          hasError: true,
          helperText: 'Dies ist keine gültige ISBN-13.'
        });
      }
    }

  }, [isbn]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect (() => {
    if(scannedValue) {
      handleChangeFromScanner(scannedValue);
    }
    return () => {
      onChange(''); //reset when unmounting
    };
  }, [scannedValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const submitHandler = async (event) => {
    event.preventDefault();
    if (!error?.hasError) {
      submitData();
    }
  }

  return (
    <>
      Klicke unten auf 'Scannen' oder gib die ISBN selbst ein:
      <form noValidate autoComplete="off" onSubmit={submitHandler}>
        <TextField
          error={error?.hasError}
          helperText={error?.helperText}
          id="book-isbn"
          label="ISBN"
          fullWidth
          value={isbn}
          onChange={handleChange}
          size="small"
          variant="outlined"
          margin="dense"
          color="secondary"
        />
      </form>
      <StepButton sx={{ color: 'primary.dark' }} onClick={handleBack}>Zurück</StepButton>
      <StepButton variant='outlined' color="secondary" onClick={submitHandler} disabled={error?.hasError}>
        {loading && <Progress size={24} />}
        Weiter
      </StepButton>
    </>

  )
}
