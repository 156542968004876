import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';

import { useSnackbar } from 'notistack';

import { getLibraries } from '../../api/endpoints';
import { useApi } from '../../hooks';
import { LibraryForm } from './LibraryForm';

const Library = (props) => {
  const {library, functionRef} = props;
  return (
    <>
      <ListItem 
        sx={{ alignItems: 'flex-start' }}
        secondaryAction={
          <LibraryForm 
            title="Sammlung bearbeiten"
            text={`${library?.name} umbenennen.`}
            mode="edit"
            icon={<EditOutlinedIcon />}
            library={library}
            executeFunction={functionRef}
          />
        }>
        <ListItemIcon sx={{ mt: 0.5 }}>
          <LocalLibraryOutlinedIcon />
        </ListItemIcon>
        <ListItemText
          sx={{ mt: 0 }}
          primary={library.name}
          secondary={library?.owner?.map(o => o.name).join(', ')}
        />
      </ListItem>
      <Divider variant="inset" />
    </>
  );
};

const LibrarySkeleton = () => {
  return (
    <>
      <ListItem sx={{ alignItems: 'flex-start' }}>
        <ListItemIcon sx={{ mt: 0.5 }}>
          <Skeleton variant="circular" width={40} height={40} />  
        </ListItemIcon>
        <ListItemText>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        </ListItemText>
      </ListItem>
      <Divider variant="inset" />
    </>
  );
};

const AddLibrary = ( props ) => {
  const {functionRef} = props;
  return (
    <>
      <ListItem 
        sx={{ alignItems: 'flex-start' }}
        secondaryAction={
          <LibraryForm 
            title="Sammlung anlegen"
            text="Bitte gib einen Namen für Deine Sammlung ein."
            mode="add"
            icon={<AddCircleOutlineOutlinedIcon />}
            executeFunction={functionRef}
          />
        }>
        <ListItemIcon sx={{ mt: 0.5 }}>
          <LocalLibraryOutlinedIcon />
        </ListItemIcon>
        <ListItemText
          sx={{ mt: 0 }}
          primary="Sammlung anlegen"
          secondary="Ohne Sammlung keine Bücher."
        />
      </ListItem>
      <Divider variant="inset" />
    </>
  )
};

export function LibraryList() {
  const [libraries, setLibraries] = useState([]);
  const [page, setPage] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const { loading, error: getError, data: librariesData, metadata, executeRequest: exceuteGet, cancelRequest: cancelGet } = useApi(getLibraries);

  const initReload = () => {
    exceuteGet();
  }

  useEffect(() => {
    librariesData && setLibraries(librariesData);
  }, [librariesData]); // eslint-disable-line

  useEffect(() => {
    setPage(metadata);
  }, [metadata]); // eslint-disable-line

  useEffect(() => {
    if (getError) {
      enqueueSnackbar('Fehler beim Laden Deiner Sammlung(en).', {
        variant: 'error',
      });
    }
  }, [getError]); // eslint-disable-line

  useEffect(() => {
    exceuteGet();
    return () => {
      cancelGet();
    }
  }, []); // eslint-disable-line

  return (
    <List>
      {loading && <LibrarySkeleton />}
      {libraries?.map((library, index) => (
        <Library library={library} key={index} functionRef={initReload} />
      ))}
      {page?.totalElements === 0 && <AddLibrary functionRef={initReload} />}
    </List>    
  );
}
