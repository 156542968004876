import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MobileNavigation from './MobileNavigation';
import DesktopNavigation from './DesktopNavigation';

export default function Navigation(props) {
  const { children } = props;
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <MobileNavigation>
        {children}
      </MobileNavigation>
    )
  }

  return (
    <DesktopNavigation>
      {children}
    </DesktopNavigation>
  )

}
