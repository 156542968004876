import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

import { patchAuthorBookRelation, deleteBookFromAuthor } from '../../../api/endpoints';
import { useApi, useTimeout, useDeviceParameter } from '../../../hooks';

import AuthorInput from '../../AuthorInput';

const DEFAULT_ERROR = {
  hasError: false,
  helperText: ' '
};

const renderStatusIcon = (status) => {
  switch (status) {
    case 'unadded':
      return <AddOutlinedIcon />;
    case 'unremoved':
      return <RemoveOutlinedIcon />;
    default:
      return <></>;
  }
}

const RemoveAction = ({ author, callback }) => {
  return (
    <IconButton aria-label="edit" onClick={() => callback(author)}>
      <ClearOutlinedIcon />
    </IconButton>
  );
}


export default function EditAuthorsForm(props) {
  const { setFormTitle, object: book, onSuccess, onCancel, onError, children } = props;
  const [formAuthors, setFormAuthors] = useState(book?.authors ? book.authors : []);
  const [closeTimeout, setCloseTimeout] = useState(null);
  const [error, setError] = useState(DEFAULT_ERROR);
  const { isMobile } = useDeviceParameter();

  const {
    loading: patching,
    error: patchError,
    responseStatus: patchResponseStatus,
    executeRequest: executePatch,
    cancelRequest: cancelPatch
  } = useApi(patchAuthorBookRelation(null));

  const {
    loading: removing,
    error: removeError,
    responseStatus: removeResponseStatus,
    executeRequest: executeRemove,
    cancelRequest: cancelRemove
  } = useApi(deleteBookFromAuthor(null, book?.id));

  useTimeout(() => {
    if (patchResponseStatus === 204 || removeResponseStatus === 204) {
      onSuccess();
    }
  }, closeTimeout);

  useEffect(() => {
    if (removeError || patchError) {
      setCloseTimeout(null);
    }
  }, [removeError, patchError]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateAuthors = (targetAuthor, newStatus) => {
    let updatedAuthors = formAuthors.map(author => {
      if (author.id === targetAuthor.id) {
        if (author.status === 'unadded' && newStatus === undefined) {
          return author;
        }
        return { ...author, status: newStatus };
      }
      return author;
    });
    setFormAuthors(updatedAuthors);
  }

  const removeAuthorFromList = (authorToDelete) => {
    authorToDelete.status === 'unadded'
      ? setFormAuthors(formAuthors.filter(author => author.id !== authorToDelete.id))
      : updateAuthors(authorToDelete, 'removed');
  }

  const handleAuthorToList = (newAuthor) => {
    formAuthors.some(author => author.id === newAuthor.id)
      ? updateAuthors(newAuthor, undefined)
      : setFormAuthors(formAuthors => formAuthors.concat({ ...newAuthor, status: 'unadded' }));
  }

  const initiatePatch = (authorId) => {
    executePatch(
      {},
      { bookIds: [book?.id] },
      patchAuthorBookRelation(authorId)
    )
  }

  const initiateRemove = (author) => {
    executeRemove(
      {},
      {},
      deleteBookFromAuthor(author.id, book?.id)
    );
  }

  const handleSave = (event) => {
    event.preventDefault();
    formAuthors
      .filter(author => author.status === "unadded")
      .map(author => { return initiatePatch(author.id) });

    formAuthors
      .filter(author => author.status === "removed")
      .map(author => { return initiateRemove(author) });

    setCloseTimeout(1000);
  }

  const handleCancel = () => {
    setFormAuthors(book?.authors ? book.authors : []);
    onCancel();
  }

  useEffect(() => {
    if (formAuthors.every(author => author.status === 'removed')) {
      onError("Füge mindestens einen Autor hinzu.");
      setError({
        hasError: true,
        helperText: 'Mind. 1 Autor notwendig'
      });
    } else {
      setError(DEFAULT_ERROR);
    }
  }, [formAuthors]); // eslint-disable-line


  useEffect(() => {
    setFormTitle("Autoren bearbeiten");

    return () => {
      cancelPatch();
      cancelRemove();
    }
  }, []); // eslint-disable-line

  return (
    <>
      {children}

      <List
        dense={true}
        sx={{
          ...(isMobile && {
            minHeight: '100px',
          }),
          ...(!isMobile && {
            minHeight: '150px',
          })
        }}
      >
        {formAuthors.filter(author => author.status !== "removed").map((author) => (
          <ListItem
            key={author.id}
            secondaryAction={<RemoveAction author={author} callback={removeAuthorFromList} />}
          >
            <ListItemIcon sx={{ minWidth: '28px' }}>
              {renderStatusIcon(author.status)}
            </ListItemIcon>
            <ListItemText primary={author.firstName.concat(' ', author.lastName)} />
          </ListItem>
        ))}
      </List>

      <form noValidate autoComplete="off" onSubmit={handleSave}>
        <AuthorInput addAuthorToList={handleAuthorToList} />
      </form>
      <Grid container
        direction="column"
        justifyContent="space-evenly"
        alignItems="space-evenly"
      >
        <Button
          autoFocus
          variant="contained"
          color="secondary"
          sx={{ my: 1 }}
          onClick={handleSave}
          disabled={error?.hasError || patching || removing}
        >
          {(patching || removing) && (
            <CircularProgress
              size={24}
              sx={{
                color: 'secondary.dark',
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
          Speichern
        </Button>

        <Button
          sx={{ my: 1 }}
          color="primary"
          variant="contained"
          disableElevation
          onClick={handleCancel}
          disabled={patching || removing}
        >
          Abbrechen
        </Button>
      </Grid>
    </>
  )
}