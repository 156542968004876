import React, { useState, useEffect } from 'react';

import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import BookSearchBar from '../BookSearchBar';
import BookList from '../BookList';
import { LibraryInformation } from './BooksDetails/LibraryInformation';

import { getBooks, searchBooks } from '../../api/endpoints';
import { useApi, useDebounce, usePrevious } from '../../hooks';
import { useScanner } from '../BarcodeScanner';
import { useSearch } from '../../utils/SearchProvider';
import { stopWords } from '../../utils/Validation';

const Progress = styled(CircularProgress)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
});

export function Books() {
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const [books, setBooks] = useState([]);
  const [responseMeta, setResponseMeta] = useState({ last: true });
  const { queryParameter, updateQueryParameter, updateQueryParameters } = useSearch();
  const { scannedValue } = useScanner();
  const { enqueueSnackbar } = useSnackbar();

  const { loading, error: getError, data: booksData, metadata, executeRequest, cancelRequest } = useApi(getBooks);
  const { loading: searching, error: searchError, data: searchData, metadata: searchMetadata, executeRequest: searchRequest, cancelRequest: cancelSearch } = useApi(searchBooks);

  const debouncedSearchString = useDebounce(queryParameter.searchString, 500);

  let previousSearchString = usePrevious(debouncedSearchString);
  let previousPageNumber = usePrevious(queryParameter.pageNumber);
  let previousSortingOptions = usePrevious([queryParameter.sortKey, queryParameter.sortDirection].join(','));
  let previousPageSize = usePrevious(queryParameter.pageSize);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  useEffect(() => {
    return () => {
      // using localstorage in this fashion should not be done... TODO: find proper solution
      const localStorage = window.localStorage.getItem("queryParams");

      if (localStorage) {
        const item = JSON.parse(localStorage);
        updateQueryParameters({
          ...queryParameter,
          searchString: item.searchString,
          pageNumber: 0,
          sortDirection: item.sortDirection,
          sortKey: item.sortKey,
          filter: item.filter,
          pageSize: item.pageSize,
          offset: item.offset
        });
      }

      cancelRequest();
      cancelSearch();
    }
  }, []); // eslint-disable-line



  useEffect(() => {
    let error = getError ? getError : searchError;
    if (error) {
      enqueueSnackbar('Fehler beim Laden.', {
        variant: 'error',
      });
    }
  }, [getError, searchError]); // eslint-disable-line

  const loadBooks = () => {
    if (!loading && !searching) {

      if (!(previousPageNumber !== queryParameter.pageNumber)
        || previousSearchString !== debouncedSearchString
        || previousSortingOptions !== [queryParameter.sortKey, queryParameter.sortDirection].join(',')
        || previousPageSize !== queryParameter.pageSize
      ) {
        setBooks([]);
      }

      if (debouncedSearchString.length > 2 && stopWords.indexOf(debouncedSearchString) === -1) {
        searchRequest({
          query: debouncedSearchString,
          size: queryParameter.pageSize,
          page: queryParameter.pageNumber,
          sort: queryParameter.sortKey + ',' + queryParameter.sortDirection,
          ...(queryParameter.filter.length !== 0 && { filter: queryParameter.filter.join(',') }),
        });
      } else if (debouncedSearchString.length > 0 && debouncedSearchString.length <= 2) {
        console.debug("do nothing");
      } else {
        executeRequest({
          size: queryParameter.pageSize,
          page: queryParameter.pageNumber,
          sort: queryParameter.sortKey + ',' + queryParameter.sortDirection,
          ...(queryParameter.filter.length !== 0 && { filter: queryParameter.filter.join(',') }),
        });
      }
    }
  }

  useEffect(() => {
    if (booksData && metadata) {
      if (books.length === 0 || metadata.pageable.offset !== queryParameter.offset) {
        booksData?.forEach((book, i) => {
          book.authors = book.authors?.map((author) => author.firstName + ' ' + author.lastName).join(", ");
        });
        setResponseMeta(metadata);
        setBooks(books.concat(booksData));
      }
    }
  }, [booksData]); // eslint-disable-line

  useEffect(() => {
    if (searchData && searchMetadata) {
      if (books.length === 0 || searchMetadata.pageable.offset !== queryParameter.offset) {
        searchData?.forEach((book, i) => {
          book.authors = book?.authors?.map((author) => author.firstName + ' ' + author.lastName).join(", ");
        });
        setResponseMeta(searchMetadata);
        setBooks(books.concat(searchData));
      }
    }
    // eslint-disable-next-line
  }, [searchData]);

  useEffect(() => {
    loadBooks();
  }, [ // eslint-disable-line
    queryParameter.pageSize, // eslint-disable-line
    queryParameter.pageNumber, // eslint-disable-line
    queryParameter.sortKey, // eslint-disable-line
    queryParameter.sortDirection, // eslint-disable-line
    queryParameter.filter,
    debouncedSearchString // eslint-disable-line
  ]); // eslint-disable-line

  useEffect(() => {
    if (searchParams && Object.keys(searchParams).length !== 0) {
      const currentParams = Object.fromEntries([...searchParams]);
      updateQueryParameters({
        ...queryParameter,
        searchString: currentParams.isbn ? currentParams.isbn : currentParams.search,
      });
    }
    return () => {
    }
  }, [searchParams]); // eslint-disable-line

  useEffect(() => {
    if (scannedValue) {
      updateQueryParameters({
        ...queryParameter,
        searchString: scannedValue,
      })
    }
  }, [scannedValue]); // eslint-disable-line

  return (
    <div>
      <Grid
        id="books-grid-container"
        container
        direction={isMobile ? 'column' : 'row'}
        justifyContent="flex-start"
        spacing={0}
      >
        {
          isMobile && (
            <Grid item xs={12}>
              <BookSearchBar />
            </Grid>
          )
        }

        <Grid item container xs={12} sm={8} md={6} sx={{ ...(!isMobile && { pt: 2 }) }}>
          <LibraryInformation parentLoading={loading || searching} currentElements={books?.length} totalElements={responseMeta?.totalElements}>
            <Typography variant="overline" component="h2" sx={{ pr: 1 }}>
              {(books?.length > 0) && `${books?.length} / `}
              {(responseMeta?.totalElements > 1 || responseMeta?.totalElements === 0) ? `${responseMeta?.totalElements} Bücher` : `${responseMeta?.totalElements} Buch`}
            </Typography>
          </LibraryInformation>
        </Grid>

        <Grid item xs={0} sm={6}></Grid>

        <Grid container item sm={8} md={6}
          id="books-list-container"
          sx={{
            ...(!isMobile && {
              overflow: 'hidden',
              '&:hover': { overflow: 'auto' },
              height: `calc(100vh - ${theme.spacing(16)})`,
              '::-webkit-scrollbar': { width: '5px' },
              '::-webkit-scrollbar-track': { backgroundColor: 'primary.light' },
              '::-webkit-scrollbar-thumb': { backgroundColor: 'secondary.light' },
              '::-webkit-scrollbar-thumb::hover': { backgroundColor: 'secondary.main' },
            })
          }}
        >
          <BookList
            books={books}
            next={() => updateQueryParameter('pageNumber', queryParameter.pageNumber + 1)}
            hasMore={!responseMeta.last}
            pageSize={queryParameter.pageSize}
          />


          {!responseMeta?.last && (
            <Grid container item xs={12} alignItems="center" justifyContent="center">
              <Button
                color="secondary"
                variant="outlined"
                size="small"
                onClick={() => updateQueryParameter('pageNumber', queryParameter.pageNumber + 1)}
              >
                {(loading || searching) && <Progress size={24} />}
                Weitere laden
              </Button>
            </Grid>
          )}

          {(books?.length === 0 && loading) && (
            <Grid container item xs={12} alignItems="center" justifyContent="center">
              <Grid item>
                <CircularProgress
                  color="secondary"
                  size="4rem"
                  thickness={1}
                />
              </Grid>
            </Grid>
          )}

        </Grid>

      </Grid>
    </div>
  );
}
