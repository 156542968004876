import React, { useState, useContext, createContext } from 'react';

const ScannerContext = createContext(null);

export const useScanner = () => {
  const [ scannedValue, setScannedValue ] = useContext(ScannerContext);

  const handleScannedValue = (newScannedValue) => {
    setScannedValue(newScannedValue);
  }
  return {
    scannedValue: scannedValue,
    onChange: handleScannedValue
  };
}

export const ScannerProvider = ({ children }) => {
  const [ scannedValue, setScannedValue ] = useState('');

  return (
    <ScannerContext.Provider value={[scannedValue, setScannedValue]}>
      {children}
    </ScannerContext.Provider>
  )
}
