import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

export const useApi = (config) => {
  const { getAccessTokenSilently } = useAuth0();
  const [state, setState] = useState({
    error: null,
    loading: false,
    data: null,
    metadata: null,
    responseStatus: null,
  });

  const source = axios.CancelToken.source();

  const executeRequest = async (params, data, overwriteConfig) => {
    try {
      setState({
        ...state,
        loading: true,
      });
      const accessToken = await getAccessTokenSilently();

      const response = await axios({
        ...(overwriteConfig ? overwriteConfig : config),
        headers: {
          ...config.headers,
          Authorization: `Bearer ${accessToken}`,
        },
        cancelToken: source.token,
        params: {
          ...config.params,
          ...params,
        },
        data: {
          ...data,
        }
      });

      let { content, ...metadata} = response.data;

      setState({
        ...state,
        data: content ? content : response.data, //cover non array case
        metadata: metadata ? metadata : {}, //cover non array case
        error: null,
        loading: false,
        responseStatus: response?.status,
      });
    } catch (error) {
      if (!axios.isCancel(error)) {
        setState({
          ...state,
          error,
          loading: false,
          responseStatus: error?.status,
        });
      }
    }
  }

  const cancelRequest = () => {
    source.cancel();
  }


  /*useEffect(() => {
    (async () => {

    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps*/

  return {
    ...state,
    executeRequest,
    cancelRequest,
  };
};
