import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import { useParams, useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';

import { DetailNavigation } from '../Navigation';
import { getBook, deleteBook } from '../../api/endpoints';
import { useApi, useTimeout, useDeviceParameter } from '../../hooks';
import FormDrawer from '../FormDrawer/FormDrawer';
import EditTitleForm from './EditFormComponents/EditTitleForm';
import { BookCover, BookDetails, BookOptions } from './BookDetails';
import { useRecentlyViewedBooks } from '../Sidebar/RecentlyViewedBooks';


export function Book() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [book, setBook] = useState({});
  const [redirectTimeout, setRedirectTimeout] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const { loading, data, error, executeRequest, cancelRequest } = useApi(getBook(id));
  const {
    loading: deleting,
    error: deleteError,
    responseStatus: deleteStatus,
    executeRequest: executeDelete,
    cancelRequest: cancelDelete } = useApi(deleteBook(id));
  const { enqueueSnackbar } = useSnackbar();
  const { isMobile } = useDeviceParameter();
  const { addBook, removeBook } = useRecentlyViewedBooks();

  const toggleEditMode = () => {
    setEditMode(!editMode);
  }

  const handleDeleteBook = () => {
    executeDelete();
  }

  const refreshBook = () => {
    executeRequest();
  }

  useTimeout(() => {
    if (deleteStatus && deleteStatus === 204) {
      navigate('/');
    }
  }, redirectTimeout);

  useEffect(() => {
    if (deleteStatus && deleteStatus === 204) {
      removeBook(book);
      setRedirectTimeout(300);
      enqueueSnackbar('Buch gelöscht..', {
        variant: 'success',
      });
    }
  }, [deleteStatus]); // eslint-disable-line

  useEffect(() => {
    if (error) {
      enqueueSnackbar('Fehler beim Laden.', {
        variant: 'error',
      });
    }
  }, [error]); // eslint-disable-line

  useEffect(() => {
    if (deleteError) {
      setRedirectTimeout(null);
      enqueueSnackbar('Buch konnte nicht gelöscht werden.', {
        variant: 'error',
      });
    }
  }, [deleteError]); // eslint-disable-line

  useEffect(() => {
    if (data) {
      setBook(data);
      addBook(data);
    }
  }, [data]); // eslint-disable-line

  useEffect(() => {
    executeRequest();
  }, [id]); // eslint-disable-line

  useEffect(() => {
    return () => {
      cancelRequest();
      cancelDelete();
    }
  }, []); // eslint-disable-line


  if (isMobile) {
    return (
      <>
        <DetailNavigation>
          <BookOptions
            book={book}
            updateCallback={refreshBook}
            deleteCallback={handleDeleteBook}
            toggleCallback={toggleEditMode}
            loading={loading}
            deleting={deleting}
            editMode={editMode}
          />
        </DetailNavigation>

        <BookCover book={book} />

        <Grid
          container
          spacing={0}
          justifyContent="space-between"
          sx={{ pt: 2, pl: 2, minHeight: '80px' }}
        >
          <Grid item xs={editMode ? 9 : 12} sm={6} zeroMinWidth>
            <Typography variant="h5" component="h2" noWrap={editMode ? true : false} sx={{ pl: 1 }}>
              {book.title}
            </Typography>
          </Grid>
          <Grid item xs={2} zeroMinWidth>
            {editMode && <FormDrawer form={EditTitleForm} object={book} onEvent={refreshBook} />}
          </Grid>
        </Grid>

        <BookDetails book={book} editMode={editMode} callback={refreshBook} />
      </>
    );
  }

  //desktop
  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="flex-start"
        sx={{ pr: 2 }}
        id="book-details-container-outer"
      >
        <Grid
          container
          item
          direction="column"
          sm={6}
          md={5}
          id="book-details-container-inner"
        >

          <Grid
            container
            item
            direction="row"
            id="book-title-container"
            sx={{ minHeight: '60px' }}
          >
            <Grid item sm={10} md={11}>
              <Typography variant="h5" component="h2" sx={{ pl: 1 }}>
                {book.title}
              </Typography>
            </Grid>

            <Grid item sm={1}>
              <Box sx={{ float: 'right', button: { p: 0.5 }, ...(!editMode && { visibility: 'hidden' }) }}>
                <FormDrawer form={EditTitleForm} object={book} onEvent={refreshBook} />
              </Box>
            </Grid>
          </Grid>

          <Grid item>
            <BookDetails book={book} editMode={editMode} callback={refreshBook} />
          </Grid>

        </Grid>

        <Grid item container direction="row" sm={2} md={3} alignItems="flex-start">
          <BookOptions
            book={book}
            updateCallback={refreshBook}
            deleteCallback={handleDeleteBook}
            toggleCallback={toggleEditMode}
            loading={loading}
            deleting={deleting}
            editMode={editMode}
          />
        </Grid>

        <Grid item sm={4} >
          <BookCover book={book} />
        </Grid>

      </Grid>

    </>
  );
}
