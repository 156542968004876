import { Books, Book , NewBook }  from '../Books';
import { Author, Authors } from '../Authors';
import { Error } from '../Error';
import { Profile } from '../Profile';

export const routes = [
  { path: "/", name: "Bücher", Component: Books },
  { path: "/authors", name: "Autoren", Component: Authors },
  { path: "/authors/:id", name: "Autor", Component: Author },
  { path: "/books/:id", name: "Buch", Component: Book },
  { path: "/books/add", name: "Buch", Component: NewBook },
  { path: "/profile", name: "Profil & Einstellungen", Component: Profile },
  { path: "*", name: "Fehler", Component: Error },
];
