import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';

import AuthorInput from '../../AuthorInput';

const AuthorChip = styled(Chip)({
});

const ChipList = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  '& > $authorChip': {
    margin: theme.spacing(0.5),
  },
}));

const StepButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginRight: theme.spacing(1),
}));



export default function BookTitle({book, handleNext, handleBack}) {
  const [authors, setAuthors] = useState(book?.authors ? book.authors : []);

  const submitData = () => {
    handleNext({
      ...book,
      authors: authors,
    });
  }

  const authorIncluded = (id) => {
    return authors.some(function(author) {
      return author.id === id;
    })
  };

  const handleAuthorToList = ( newAuthor ) => {

    if( !authorIncluded(newAuthor.id) ) {
      setAuthors(authors => authors.concat(newAuthor));
    }
  };

  const removeAuthorFromList = ( authorToDelete ) => {
    let index = authors.findIndex(author => author.id === authorToDelete.id);
    let updatedAuthors = authors.slice();
    updatedAuthors.splice(index, 1)
    setAuthors(updatedAuthors);
  }

  const submitHandler = (event) => {
    event.preventDefault();
    if (authors.length > 0) {
      submitData();
    }
  }

  return (
    <>
      <form noValidate autoComplete="off" onSubmit={submitHandler}>
        <AuthorInput addAuthorToList={handleAuthorToList}/>
      </form>
      <ChipList>
        {authors.length === 0 && (
          <AuthorChip
            variant="outlined"
            disabled
            label="Füge einen Autor hinzu!"
          />
        )}
        {authors.map((author) =>
          <AuthorChip
            key={author.id}
            onDelete={() => removeAuthorFromList(author)}
            label={author.firstName + ' ' + author.lastName}
          />
        )}
      </ChipList>
      <StepButton sx={{ color: 'primary.dark' }}  onClick={handleBack}>Zurück</StepButton>
      <StepButton variant='outlined' color="secondary" onClick={submitData} disabled={authors.length === 0}>Weiter</StepButton>
    </>

  )
}
