import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import LocalLibraryRoundedIcon from '@mui/icons-material/LocalLibraryRounded';
import { useAuth0 } from '@auth0/auth0-react';

const RedirectBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  flexGrow: 1,
  paddingTop: theme.spacing(2),
}));

export default function LandingPage() {
  const { loginWithRedirect } = useAuth0();

  return (
    <RedirectBox>
      <IconButton aria-label="login" onClick={() => loginWithRedirect({})} size="large">
        <LocalLibraryRoundedIcon />
      </IconButton>
    </RedirectBox>
  );
}
